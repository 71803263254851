import React, { useMemo } from 'react';
import { Variable } from '../../declarations';
import LanguageCard, { LanguageCardPropTypes } from '../LanguagesCard';
import './styles.scss';
import VariableCard from './VariableCard';

export interface VariablesListPropsType {
  variables: Variable[];
  selectedOptionsIds?: number[];
  toggleOptionSelected?: (optionId: number, variableId: number) => void;
  deleteVariable?: (variableId: number) => void;
  updateVariable?: (variable: any) => void;
  skipVariable?: (variableId: number) => void;
  includeVariable?: (variableId: number) => void;
  skippedVariablesIds?: number[];
  disabled?: boolean;
  languagesCardProps?: LanguageCardPropTypes;
  isAvailableTextVariables: boolean;
}

const VariablesList: React.FC<VariablesListPropsType> = props => {
  const {
    variables,
    selectedOptionsIds,
    toggleOptionSelected,
    deleteVariable,
    skipVariable,
    includeVariable,
    skippedVariablesIds,
    disabled,
    updateVariable,
    isAvailableTextVariables,
  } = props;

  const getVariableCard = (variable: Variable) => {
    return (
      <VariableCard
        isAvailableTextVariables={isAvailableTextVariables}
        key={variable.id}
        variable={variable}
        selectedOptionsIds={selectedOptionsIds}
        toggleOptionSelected={toggleOptionSelected}
        deleteVariable={deleteVariable}
        skipVariable={skipVariable}
        includeVariable={includeVariable}
        skipped={skippedVariablesIds && skippedVariablesIds.includes(variable.id)}
        disabled={disabled}
        updateVariable={updateVariable}
      />
    );
  };

  const seriesVariables = useMemo(() => variables.filter(v => v.level === 'series'), [variables]);
  const issueVariables = useMemo(() => variables.filter(v => v.level === 'issue'), [variables]);
  const pageVariables = useMemo(() => variables.filter(v => v.level === 'page'), [variables]);
  const variablesWithoutLevel = useMemo(() => variables.filter(v => !v.level), [variables]);

  if (!variables.length && !props.languagesCardProps) {
    return <></>;
  }

  return (
    <div className="variables-list">
      <h4>Variables</h4>
      {!!variablesWithoutLevel.length && variablesWithoutLevel.map(getVariableCard)}
      {!!seriesVariables && !!props.languagesCardProps && (
        <p className="variables-list_text">Variables for series</p>
      )}
      {props.languagesCardProps && <LanguageCard {...props.languagesCardProps} />}
      {!!seriesVariables.length && <>{seriesVariables.map(getVariableCard)}</>}
      {!!issueVariables.length && (
        <>
          <p className="variables-list_text">Variables for issue</p>
          {variables.filter(v => v.level === 'issue').map(getVariableCard)}
        </>
      )}
      {!!pageVariables.length && (
        <>
          <p className="variables-list_text">Variables for page</p>
          {variables.filter(v => v.level === 'page').map(getVariableCard)}
        </>
      )}
    </div>
  );
};

export default VariablesList;
