import React, { useEffect, useState } from 'react';
import { Asset, DragAsset } from '../../../declarations';
import { arrayIncludesAll } from '../../../utils/arrayIncludesAll';
import { ASSETS_TYPES } from '../../../constants';
import { Canvas, Image, Text } from './Canvas';
import './styles.scss';

export interface PagePreviewPropsType {
  assets: Asset[];
  selectedOptionsIds: number[];
  selectedLanguageId?: number | null;
  visibleAssetsIds: number[];
  saveAsset: (element: DragAsset) => void;
  pageWidth?: number;
  pageHeight?: number;
}

const PagePreview: React.FC<PagePreviewPropsType> = props => {
  const {
    assets,
    selectedOptionsIds,
    selectedLanguageId,
    visibleAssetsIds,
    saveAsset,
    pageWidth,
    pageHeight,
  } = props;

  const [assetsToShow, setAssetsToShow] = useState<Asset[]>([]);

  const isAssetLinked = (asset: Asset) => {
    return asset.options.length > 0 || asset.languageId !== null;
  };

  useEffect(() => {
    const assetsShow = assets.filter(
      asset =>
        (!isAssetLinked(asset) && visibleAssetsIds.includes(asset.id)) ||
        (isAssetLinked(asset) &&
          arrayIncludesAll(
            selectedOptionsIds,
            asset.options.map(o => o.id),
          ) &&
          (selectedLanguageId === asset.languageId || asset.languageId === null)),
    );
    setAssetsToShow(assetsShow);
  }, [assets, selectedLanguageId, selectedOptionsIds, visibleAssetsIds]);

  return (
    <div>
      <h4>Page preview</h4>
      <div className="preview-wrapper">
        {pageWidth && pageHeight && (
          <Canvas pageWidth={pageWidth} pageHeight={pageHeight}>
            {assetsToShow.map((asset: Asset, index: number) => {
              switch (asset.type) {
                case ASSETS_TYPES.img:
                  return <Image key={asset.id} id={asset.id} url={asset.url} order={index} />;
                case ASSETS_TYPES.text:
                  return (
                    <Text
                      key={asset.id}
                      id={asset.id}
                      text={asset.name}
                      order={index}
                      width={asset.width}
                      height={asset.height}
                      offsetLeft={asset.offsetLeft}
                      offsetTop={asset.offsetTop}
                      saveAsset={saveAsset}
                      item={asset}
                    />
                  );
              }
            })}
          </Canvas>
        )}
      </div>
    </div>
  );
};

export default PagePreview;
