import React from 'react';
import { Row } from 'react-bootstrap';
import { Issue } from '../../../declarations';
import IssueCard from '../IssueCard';
import IssueCardEmpty from '../IssueCardEmpty';
import './styles.scss';

export interface IssuesGridPropsType {
  issues: Issue[];
  issuesNumber: number;
}

const IssuesGrid: React.FC<IssuesGridPropsType> = (props) => {
  const { issues, issuesNumber } = props;

  const empty = new Array(issuesNumber).fill(0).map((_, i) => i+1);

  const getIssue = (issueNumber: number) => {
    const issue = issues.find(i => i.number === issueNumber);
    if(!issue) {
      return <IssueCardEmpty key={issueNumber} issueNumber={issueNumber} />
    }

    return <IssueCard key={issueNumber} issue={issue} />
  }

  return (
    <div className="issues-grid">
      <Row>
        <h2>Issues</h2>
      </Row>
      <Row>
        {empty.map(getIssue)}
      </Row>
    </div>
  );
};

export default IssuesGrid;
