import React, { useCallback, useRef, useState } from 'react';
import { Card, Collapse, Dropdown, ListGroup } from 'react-bootstrap';
import { Language } from '../../declarations';
import { useClickOutside } from '../../utils/useClickOutside';
import { ReactComponent as MoreIcon} from '../AssetsList/AssetsListItem/Options.svg';
import { ReactComponent as ChevronIcon } from '../VariablesList/VariableCard/Big_arrow.svg';
import LanguagesCardOption from './LanguagesCardOption';
import './styles.scss';

export interface LanguageCardPropTypes {
  languages: Language[];
  selectedLanguageId?: number;
  selectLanguage: (languageId: number) => void;
  skipped?: boolean;
  toggleLanguageSkipped?: () => void;
  disabled?: boolean;
}

const LanguageCard: React.FC<LanguageCardPropTypes> = (props) => {
  const {
    languages,
    skipped,
    disabled,
    toggleLanguageSkipped,
    selectLanguage,
    selectedLanguageId,
  } = props;
  const [visible, setVisible] = useState<boolean>(!skipped);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const dropdownRef = useRef(null);
  const hideDropdown = useCallback((e: any) => {
    e.stopPropagation();
    if(dropdownVisible) {
      setDropdownVisible(false);
    }
  }, [dropdownVisible]);

  useClickOutside(dropdownRef, hideDropdown);

  const toggleVisible = useCallback(() => {
    if(skipped) {
      return;
    }
    setVisible(!visible);
  }, [skipped, visible]);

  const toggleDropdown = useCallback((e: any) => {
    e.stopPropagation();
    setDropdownVisible(!dropdownVisible);
  }, [dropdownVisible]);

  const skipLanguage = useCallback(() => {
    if(!toggleLanguageSkipped) {
      return;
    }
    setVisible(false);
    setDropdownVisible(false);
    toggleLanguageSkipped();
  }, [toggleLanguageSkipped]);
  
  const includeLanguage = useCallback(() => {
    if(!toggleLanguageSkipped) {
      return;
    }
    setVisible(false);
    setDropdownVisible(false);
    toggleLanguageSkipped();
  }, [toggleLanguageSkipped]);

  return (
    <Card className={skipped ? 'languages-card languages-card--skipped' : 'languages-card'}>
      <Card.Header onClick={toggleVisible} className="languages-card_header">
        <div className="languages-card_header_title-wrapper">
          <Card.Title className={skipped ? 'languages-card_title languages-card_title--skipped' : 'languages-card_title'}>
            Languages
          </Card.Title>
        </div>
        <div className="languages-card_header_icons-wrapper">
          {!skipped && (
            <>
              <ChevronIcon className={visible ? 'arrow-icon arrow-icon--open' : 'arrow-icon arrow-icon--close'} />
            </>
          )}
          {!disabled && (
            <>
              <div className="more-background" onClick={toggleDropdown}>
                <MoreIcon className="more-icon" />
              </div>
              <Dropdown
                className="languages-card_dropdown"
                show={dropdownVisible}
                ref={dropdownRef}
              >
                <Dropdown.Menu>
                  {toggleLanguageSkipped && !skipped && (
                    <Dropdown.Item onClick={skipLanguage}>Skip</Dropdown.Item>
                  )}
                  {toggleLanguageSkipped && skipped && (
                    <Dropdown.Item onClick={includeLanguage}>Include</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </div>
      </Card.Header>
      <Collapse in={visible}>
        <ListGroup>
          <ListGroup.Item className="languages-card_user-description">
            Which language would you like to read this series?
          </ListGroup.Item>
          {languages.map(l => (
            <LanguagesCardOption
              key={l.id}
              language={l}
              selectLanguage={selectLanguage}
              selected={!!selectedLanguageId && selectedLanguageId === l.id}
            />
          ))}
        </ListGroup>
      </Collapse>
    </Card>
  );
};

export default LanguageCard;
