import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { Button, Form, FormGroup, InputGroup, Modal, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { ASSETS_TYPES } from '../../constants';
import { Asset } from '../../declarations';
import feathersApp from '../../utils/feathers';
import { ReactComponent as DeleteIcon } from '../../images/garbage.svg';
import './styles.scss';

export interface AddAssetModalPropsType {
  visible: boolean;
  onClose(asset?: Array<Partial<Asset>>): void;
}

const AddAssetModal: React.FC<AddAssetModalPropsType> = props => {
  const { visible, onClose } = props;

  const schema = Yup.object({
    name: Yup.string()
      .max(12, 'Too Long!')
      .required('Required'),
  });

  const [assetType, setAssetType] = useState(undefined);
  const [images, setImages] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [assetNames, setAssetNames] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<string>();

  const selectImage = useCallback(
    (url: string) => {
      if (url === selectedImage) {
        setSelectedImage(undefined);
      } else {
        setSelectedImage(url);
      }
    },
    [selectedImage],
  );

  const handleCancel = useCallback(() => {
    onClose();
    setImages([]);
    setAssetNames([]);
    setAssetType(undefined);
  }, [onClose]);

  const handleChoose = useCallback((event: any) => {
    setAssetType(event.currentTarget.value);
  }, []);

  const handleSave = useCallback(
    (values: any) => {
      const assets = assetNames.length
        ? assetNames.map((assetName, index) => ({
            name: assetName,
            url: images[index],
            thumbnailUrl: images[index],
          }))
        : [
            {
              name: values.name,
              type: ASSETS_TYPES.text,
              width: (100 * 100) / 500,
              height: (25 * 100) / 750,
              offsetTop: 45,
              offsetLeft: 45,
            },
          ];

      onClose(assets);
      setAssetType(undefined);
      setImages([]);
      setAssetNames([]);
    },
    [onClose, images, assetNames],
  );

  const setAssetName = useCallback(
    (index: number) => {
      return (e: any) => {
        setAssetNames(assetNames.map((an, i) => (i === index ? e.target.value : an)));
      };
    },
    [assetNames],
  );

  const uploadImage = useCallback(
    async (e: any) => {
      const files: File[] = [...e.target.files];
      const data = new FormData();
      files.forEach(file => {
        data.append('images', file);
      });
      setLoading(true);
      const uploads = await feathersApp.service('uploads').create(data);
      setImages([...images, ...uploads]);
      setAssetNames([...assetNames, ...uploads.map((_: any, index: number) => files[index].name)]);
      setLoading(false);
    },
    [images, assetNames],
  );

  const removeHandler = useCallback(
    (image: string, index: number) => {
      const handler = () => {
        setImages(images.filter(i => i !== image));
        setAssetNames(assetNames.filter((_, i) => i !== index));
      };
      return handler;
    },
    [images, assetNames],
  );

  return (
    <Modal className="add-asset-modal" show={visible} onHide={handleCancel}>
      {loading && (
        <div className="add-asset-modal_loader-wrapper">
          <Spinner animation="border" />
        </div>
      )}
      <Modal.Header closeButton>
        <Modal.Title>New Asset</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{ name: '' }}
        onSubmit={handleSave}
        validationSchema={assetType === ASSETS_TYPES.text ? schema : ''}
      >
        {({ handleSubmit, handleChange, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Asset type</Form.Label>
                <Form.Control
                  as="select"
                  className="add-asset-modal_asset-select"
                  name="asset-name"
                  onChange={handleChoose}
                >
                  <option disabled selected value={undefined}>
                    Select asset type
                  </option>
                  <option value={ASSETS_TYPES.text}>Character Name asset</option>
                  <option value={ASSETS_TYPES.img}>Simple asset</option>
                </Form.Control>

                <Form.Control
                  type="file"
                  onChange={uploadImage}
                  accept="image/*"
                  hidden={assetType !== ASSETS_TYPES.img}
                  multiple
                />
              </Form.Group>
              {assetType === ASSETS_TYPES.text && (
                <FormGroup className="add-asset-modal_asset-from" controlId="validationFormikName">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="name"
                      onChange={handleChange}
                      placeholder="Enter name"
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </InputGroup>
                </FormGroup>
              )}

              {assetType === ASSETS_TYPES.img &&
                images.map((image, index) => (
                  <FormGroup key={image} className="add-asset-modal_asset-from">
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="Enter name"
                        value={assetNames[index]}
                        onChange={setAssetName(index)}
                      />
                      <DeleteIcon
                        className="add-asset-modal_asset-from_delete-icon"
                        onClick={removeHandler(image, index)}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      Asset name is mandatory
                    </Form.Control.Feedback>
                    <img
                      alt="layer"
                      className={`add-asset-modal_img-preview ${
                        image === selectedImage ? 'add-asset-modal_img-preview--selected' : ''
                      }`}
                      src={image}
                      // tslint:disable-next-line: jsx-no-lambda
                      onClick={() => selectImage(image)}
                    />
                  </FormGroup>
                ))}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" type="button" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="submit" disabled={assetType === undefined ||
              (assetType === ASSETS_TYPES.img && !images.length)}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddAssetModal;
