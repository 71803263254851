import React from 'react';
import './App.scss';
import AppRouter from './Routing/AppRouter';

const App: React.FC = () => {
  return (
    <AppRouter />
  );
}

export default App;

declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    env: { apiUrl: string }
  }
}
