import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonToolbar, Container, ProgressBar, Row } from 'react-bootstrap';
import { useLoads } from 'react-loads';
import { Link, RouteComponentProps } from 'react-router-dom';
import AddVaribleModal from '../../components/AddVariableModal';
import Breadcrumbs from '../../components/Breadcrumbs';
import ErrorModal from '../../components/ErrorModal';
import VariablesList from '../../components/VariablesList';
import { Issue } from '../../declarations';
import feathersApp from '../../utils/feathers';
import { PAGE_STATUSES } from '../PageDetails/PageActions';
import PagesGrid from './PagesGrid';
import './styles.scss';

interface IssueDetailsRouteParams {
  seriesId: string;
  issueId: string;
}

const IssueDetails: React.FC<RouteComponentProps<IssueDetailsRouteParams>> = props => {
  const { issueId, seriesId } = props.match.params;
  const [issue, setIssue] = useState<Partial<Issue>>({});
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [error, setError] = useState('');
  const clearError = useCallback(() => setError(''), []);

  const saveVariable = useCallback(
    async (variable?: any) => {
      try {
        if (variable && !variable.id) {
          const data = {
            ...variable,
            issueId,
          };
          const createdVariable = await feathersApp
            .service('variables')
            .create(data, { query: { includeOptions: true } });
          setIssue({
            ...issue,
            variables: issue.variables ? [...issue.variables, createdVariable] : [createdVariable],
          });
        }
        if (variable && variable.id) {
          const updatedVar = await feathersApp.service('variables').patch(variable.id, variable);
          setIssue({
            ...issue,
            variables: issue.variables
              ? issue.variables.map(v => (v.id === updatedVar.id ? updatedVar : v))
              : undefined,
          });
        }
        setModalVisible(false);
      } catch (e) {
        setError(e.message);
      }
    },
    [issueId, issue],
  );

  const getIssue = useCallback(async () => {
    try {
      const data = await feathersApp
        .service('issues')
        .get(parseInt(issueId, 10), { query: { includePages: true, includeVariables: true } });
      setIssue(data);
    } catch (e) {
      setError(e.message);
    }
  }, [issueId]);

  const { isPending } = useLoads(getIssue, {}, [issueId]);

  const showModal = useCallback(() => setModalVisible(true), []);

  const deleteVariable = useCallback(
    async (variableId: number) => {
      try {
        await feathersApp.service('variables').remove(variableId);
        if (!issue.variables) {
          return;
        }
        setIssue({ ...issue, variables: issue.variables.filter(v => v.id !== variableId) });
      } catch (e) {
        setError(e.message);
      }
    },
    [issue],
  );

  const issueProgress = useMemo(() => {
    if (!issue.pages || !issue.pages.length || !issue.pagesNumber) {
      return 0;
    }
    const sumbittedPagesCount = issue.pages.filter(
      page =>
        page.pageStatus === PAGE_STATUSES.submitted || page.pageStatus === PAGE_STATUSES.published,
    ).length;
    return Math.floor((sumbittedPagesCount * 100) / issue.pagesNumber);
  }, [issue.pages, issue.pagesNumber]);

  const publishIssue = useCallback(async () => {
    try {
      await feathersApp.service('issues').patch(issueId, { published: true });
      setIssue({ ...issue, published: true });
    } catch (e) {
      setError(e.message);
    }
  }, [issue, issueId]);

  const unpublishIssue = useCallback(async () => {
    try {
      await feathersApp.service('issues').patch(issueId, { published: false });
      setIssue({ ...issue, published: false });
    } catch (e) {
      setError(e);
    }
  }, [issue, issueId]);

  if (isPending) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Breadcrumbs
        items={[
          {
            link: '/',
            text: 'Library',
          },
          {
            link: `/series/${seriesId}`,
            text: 'Series details',
          },
          {
            active: true,
            text: 'Issue details',
          },
        ]}
      />
      <Container>
        <Row>
          <h2>{issue.title && issue.title.default}</h2>
        </Row>
        <Row>
          <div className="issue-details_cover-wrapper">
            <img src={issue.coverUrl} alt="cover" />
          </div>
          <div>
            <div>
              <h4>Synopsis</h4>
              <p className="issue-details_description">
                {issue.description && issue.description.default}
              </p>
            </div>
            <div>
              <h4>Pages</h4>
              <p>{issue.pagesNumber}</p>
            </div>
            <div>
              <h4>Progress</h4>
              <ProgressBar
                now={issueProgress}
                label={`${Math.ceil(
                  (issueProgress / 100) * (issue.pagesNumber || 0),
                )}/${issue.pagesNumber || 0}`}
                variant="success"
                className="issue-details_progress"
              />
            </div>
            <ButtonToolbar>
              {issue.published ? (
                <Button className="series-details_button" onClick={unpublishIssue}>
                  Unpublish
                </Button>
              ) : (
                <>
                  <Button className="series-details_button" onClick={showModal}>
                    Add variable
                  </Button>
                  <Button
                    className="series-details_button"
                    as={Link}
                    to={`/series/${seriesId}/issue/${issueId}/edit`}
                  >
                    Edit
                  </Button>
                  <Button
                    className="series-details_button"
                    onClick={publishIssue}
                    disabled={issueProgress !== 100}
                  >
                    Publish
                  </Button>
                </>
              )}
            </ButtonToolbar>
            {modalVisible && (
              <AddVaribleModal
                visible={modalVisible}
                onClose={saveVariable}
                isAvailableTextVariables={false}
              />
            )}
          </div>
        </Row>
        <Row className="issue-details_pages">
          <PagesGrid pagesNumber={issue.pagesNumber || 0} pages={issue.pages || []} />
        </Row>
        <Row>
          <VariablesList
            isAvailableTextVariables={false}
            variables={issue.variables || []}
            deleteVariable={deleteVariable}
            disabled={issue.published}
            updateVariable={saveVariable}
          />
        </Row>
        <ErrorModal message={error} visible={!!error} onClose={clearError} />
      </Container>
    </>
  );
};

export default IssueDetails;
