import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { userService } from '../../services/UserService';
import feathersApp from '../../utils/feathers';

const ResetPassword: React.FC<RouteComponentProps> = (props) => {
  useEffect(() => {
    if(userService.isAuthenticated()) {
      userService.logout();
    }
  });

  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState();

  const setPasswordHandler = useCallback((e: any) => {
    setPassword(e.target.value);
  }, []);

  const resetPassword = useCallback(async (e: any) => {
    e.preventDefault();
    const { token } = queryString.parse(props.location.search);
    if(!token) {
      setErrorMessage('Bad token.');
      return;
    }
    if(!password) {
      setErrorMessage('Password cannot be empty');
      return;
    }
    try {
      await feathersApp.service('authmanagement').create({
        action: 'resetPwdLong',
        value: {
          password,
          token,
        },
      });
      props.history.push('/login');
    } catch (e) {
      setErrorMessage(e.message);
    }
  }, [password, props.location.search, props.history]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg={6}>
          <Form onSubmit={resetPassword}>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={setPasswordHandler}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Set new password
            </Button>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
