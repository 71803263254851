import React, { useCallback, useState } from 'react';
import { Container, Pagination } from 'react-bootstrap';
import { useLoads } from 'react-loads';
import ErrorModal from '../../components/ErrorModal';
import PaginationItems from '../../components/PaginationItems';
import feathersApp from '../../utils/feathers';
import LibraryHeader from './LibraryHeader';
import LibrarySeriesGrid from './LibrarySeriesGrid';
import './styles.scss';

const LibraryPage: React.FC = () => {
  const [series, setSeries] = useState([]);
  const [searchString, setSearchString] = useState<string>('');
  const [pagination, setPagination] = useState({
    limit: 8,
    skip: 0,
    total: 0,
  });
  const [error, setError] = useState('');
  const clearError = useCallback(() => setError(''), []);

  const getSeries = async () => {
    try {
      const { data, limit, skip, total } = await feathersApp.service('series').find({ query: {
        $limit: pagination.limit,
        $skip: pagination.skip,      
        $sort: {
          title: 1,
        },
        title: { $iLike: `%${searchString.toLowerCase()}%` },
      } });
      if (data.length === 0 && skip > 0) {
        setPagination({ ...pagination, skip: skip - limit });
      } else {
        setSeries(data);
        setPagination({
          limit,
          skip,
          total,
        });
      }
    } catch (e) {
      setError(e.message);
    }
  };
  
  const { isPending } = useLoads(getSeries, {}, [searchString, pagination.skip]);

  const selectPage = useCallback((n: number) => {
    setPagination({
      ...pagination,
      skip: (n - 1) * pagination.limit,
    });
  }, [pagination]);

  return (
    <Container className="library">
      <LibraryHeader searchString={searchString} submitSearch={setSearchString} />
      {isPending ? (
        <div>
          Loading
        </div>
      ): (
        <LibrarySeriesGrid series={series} />
      )}
      <Pagination className="library_pagination">
        <PaginationItems pagination={pagination} selectPage={selectPage} />
      </Pagination>
      <ErrorModal message={error} visible={!!error} onClose={clearError} />
    </Container>
  );
}
export default LibraryPage;
