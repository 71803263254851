import randomColor from 'randomcolor';
import React, { useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { ListGroup } from 'react-bootstrap';
import { OPTIONS_TYPES } from '../../../constants';
import { Option } from '../../../declarations';
import './styles.scss';

export interface VariableCardOptionPropTypes {
  option: Option;
  selected?: boolean;
  onOptionClick?: () => void;
  level?: 'series' | 'issue' | 'page';
}

export const SEED_MULTIPLIER = 3;
export const LEVEL_LUMINOSITY: { [key: string]: 'bright' | 'dark' | 'light' } = {
  issue: 'bright',
  page: 'dark',
  series: 'light',
};

const VariableCardOption: React.FC<VariableCardOptionPropTypes> = props => {
  const {
    option,
    selected,
    onOptionClick = () => {
      return;
    },
    level,
  } = props;
  const color = useMemo(() => {
    if (!level) {
      return;
    }
    return randomColor({ seed: option.id * SEED_MULTIPLIER, luminosity: LEVEL_LUMINOSITY[level] });
  }, [option.id, level]);

  const optionName = useMemo(() => {
    if (option.type === OPTIONS_TYPES.text) {
      return option.name ? option.name.default : '';
    }
    if (option.type === OPTIONS_TYPES.img) {
      return option.name || '';
    }
  }, [option.name, option.type]);

  return level ? (
    <Droppable droppableId={`variable-option-${option.id}`}>
      {(provided, snapshot) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <ListGroup.Item
            className={`option ${snapshot.isDraggingOver ? 'option--dragged-over' : ''}`}
            style={{
              backgroundColor: selected ? color && `${color}22` : undefined,
            }}
            onClick={onOptionClick}
          >
            <p>{optionName}</p>
            <div
              className="option_color-mark"
              style={{
                backgroundColor: color,
              }}
            />
          </ListGroup.Item>
          {/* {provided.placeholder} */}
        </div>
      )}
    </Droppable>
  ) : (
    <ListGroup.Item className="option">
      <p>{optionName}</p>
    </ListGroup.Item>
  );
};

export default VariableCardOption;
