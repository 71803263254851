import React, { useCallback } from 'react';
import { ReactComponent as Checkmark } from './checkmark.svg';
import './styles.scss';

export interface CheckboxPropsType {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled: boolean;
}

const Checkbox: React.FC<CheckboxPropsType> = (props) => {
  const { checked, onChange, disabled } = props;
  const onCheckboxChange = useCallback(() => {
    onChange(!checked)
  }, [checked, onChange]);

  return (
    <label className={`checkbox-container ${disabled ? 'checkbox-container--disabled': ''}`}>
      <input
        checked={checked && !disabled}
        disabled={disabled}
        className="checkbox-input"
        type="checkbox"
        onChange={onCheckboxChange}
      />
      <span className={`checkmark ${checked ? 'checkmark--checked' : ''} ${disabled ? 'checkmark--disabled' : ''}`}>
        {checked && !disabled && <Checkmark />}
      </span>
    </label>
  )
};

export default Checkbox;