import React, { useCallback, useRef, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Dropdown } from 'react-bootstrap';
import { Asset } from '../../declarations';
import { arrayIncludesAll } from '../../utils/arrayIncludesAll';
import { useClickOutside } from '../../utils/useClickOutside';
import { ReactComponent as InvisibleIcon } from './AssetsListItem/closed_eye.svg';
import { ReactComponent as VisibleIcon } from './AssetsListItem/eye.svg';
import { ReactComponent as MoreIcon } from './AssetsListItem/Options.svg';
import Checkbox from '../Checkbox';
import AssetsListItem from './AssetsListItem';
import './styles.scss';

export interface AssetsListPropsType {
  assets: Asset[];
  removeAsset: (assetId: number) => void;
  unlinkAsset: (assetId: number) => void;
  disabled?: boolean;
  variablesLevels: { [key: number]: string };
  selectedOptions: { [key: number]: number };
  selectedLanguageId?: number;
  selectedAssetsIds: number[];
  toggleAssetSelected: (assetId: number) => void;
  toggleAllAssetsSelected: () => void;
  bulkAssetsUnlink: () => void;
  bulkAssetsDelete: () => void;
  visibleAssetsIds: number[];
  toggleAssetVisible: (assetId: number) => void;
  toggleAllAssetsVisible: () => void;
  selectAssetOptions: (assetId: number, visible?: boolean) => void;
  updateAssets: () => void;
}

const SortableAssetList = (props: AssetsListPropsType) => {
  const {
    assets,
    removeAsset,
    updateAssets,
    unlinkAsset,
    disabled,
    variablesLevels,
    selectedOptions,
    selectedLanguageId,
    selectedAssetsIds,
    toggleAssetSelected,
    toggleAllAssetsSelected,
    bulkAssetsUnlink,
    bulkAssetsDelete,
    visibleAssetsIds,
    toggleAssetVisible,
    toggleAllAssetsVisible,
    selectAssetOptions,
  } = props;

  const isAssetSelected = useCallback(
    (asset: Asset) => {
      if (!asset.options.length && !!asset.languageId && asset.languageId === selectedLanguageId) {
        return true;
      }
      if (
        !!asset.options.length &&
        arrayIncludesAll(
          Object.values(selectedOptions),
          asset.options.map(o => o.id),
        ) &&
        (!asset.languageId || asset.languageId === selectedLanguageId)
      ) {
        return true;
      }
      return false;
    },
    [selectedLanguageId, selectedOptions],
  );

  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = useCallback(
    (e: any) => {
      e.stopPropagation();
      setDropdownVisible(!dropdownVisible);
    },
    [dropdownVisible],
  );
  const hideDropdown = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (dropdownVisible) {
        setDropdownVisible(false);
      }
    },
    [dropdownVisible],
  );

  useClickOutside(dropdownRef, hideDropdown);

  const bulkSelectHandler = useCallback(() => {
    toggleAllAssetsSelected();
  }, [toggleAllAssetsSelected]);

  const bulkUnlink = useCallback(() => {
    setDropdownVisible(false);
    bulkAssetsUnlink();
  }, [bulkAssetsUnlink]);

  const bulkDelete = useCallback(() => {
    setDropdownVisible(false);
    bulkAssetsDelete();
  }, [bulkAssetsDelete]);

  const toggleAllVisibility = useCallback(() => {
    toggleAllAssetsVisible();
  }, [toggleAllAssetsVisible]);

  return (
    <>
      <h4>Assets</h4>
      <div className="assets-list_header">
        <p className="assets-list_header_text">
          {!!assets.length
            ? 'Drag and drop a block of an asset to a corresponding variable'
            : 'Please, upload assets for further work'}
        </p>
        <div className="assets-list_header_icons-wrapper">
          <Checkbox
            checked={selectedAssetsIds.length === assets.length}
            onChange={bulkSelectHandler}
            disabled={!assets.length || disabled || false}
          />
          {visibleAssetsIds.length === assets.length ? (
            <VisibleIcon
              className={`eye-icon ${!assets.length ? 'eye-icon--disabled' : ''}`}
              onClick={toggleAllVisibility}
            />
          ) : (
            <InvisibleIcon className="eye-icon" onClick={toggleAllVisibility} />
          )}
        </div>
        {!disabled && (
          <>
            <div
              className={`more-background ${!assets.length ? 'more-background--disabled' : ''}`}
              onClick={toggleDropdown}
            >
              <MoreIcon className="more-icon" />
            </div>
            <Dropdown
              className="assets-list_header_dropdown"
              show={dropdownVisible}
              ref={dropdownRef}
            >
              <Dropdown.Menu>
                <Dropdown.Item disabled={!selectedAssetsIds.length} onClick={bulkUnlink}>
                  Unlink all
                </Dropdown.Item>
                <Dropdown.Item disabled={!selectedAssetsIds.length} onClick={bulkDelete}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
      </div>
      {assets.map((asset, index) => (
        <AssetsListItem
          asset={asset}
          updateAssets={updateAssets}
          key={asset.id}
          removeAsset={removeAsset}
          index={index}
          unlinkAsset={unlinkAsset}
          disabled={disabled}
          variablesLevels={variablesLevels}
          variablesSelected={isAssetSelected(asset)}
          selected={selectedAssetsIds.includes(asset.id)}
          toggleAssetSelected={toggleAssetSelected}
          visible={visibleAssetsIds.includes(asset.id)}
          toogleVisible={toggleAssetVisible}
          selectAssetOptions={selectAssetOptions}
        />
      ))}
    </>
  );
};

const AssetsList: React.FC<AssetsListPropsType> = props => {
  const {
    assets,
    removeAsset,
    unlinkAsset,
    disabled,
    variablesLevels,
    selectedOptions,
    selectedLanguageId,
    selectedAssetsIds,
    toggleAssetSelected,
    toggleAllAssetsSelected,
    bulkAssetsUnlink,
    bulkAssetsDelete,
    visibleAssetsIds,
    toggleAssetVisible,
    toggleAllAssetsVisible,
    selectAssetOptions,
    updateAssets,
  } = props;

  return (
    <Droppable isDropDisabled={disabled} droppableId="assets">
      {(provided, snapshot) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <SortableAssetList
            unlinkAsset={unlinkAsset}
            updateAssets={updateAssets}
            assets={assets}
            removeAsset={removeAsset}
            disabled={disabled}
            variablesLevels={variablesLevels}
            selectedOptions={selectedOptions}
            selectedLanguageId={selectedLanguageId}
            selectedAssetsIds={selectedAssetsIds}
            toggleAssetSelected={toggleAssetSelected}
            toggleAllAssetsSelected={toggleAllAssetsSelected}
            bulkAssetsUnlink={bulkAssetsUnlink}
            bulkAssetsDelete={bulkAssetsDelete}
            visibleAssetsIds={visibleAssetsIds}
            toggleAssetVisible={toggleAssetVisible}
            toggleAllAssetsVisible={toggleAllAssetsVisible}
            selectAssetOptions={selectAssetOptions}
          />
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default AssetsList;
