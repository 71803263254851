import React from 'react'
import { Route, Switch } from 'react-router-dom';
import IssueDetails from '../pages/IssueDetails';
import LibraryPage from '../pages/Library';
import NewIssue from '../pages/NewIssue';
import NewPage from '../pages/NewPage';
import NewSeries from '../pages/NewSeries';
import PageDetails from '../pages/PageDetails';
import SeriesDetails from '../pages/SeriesDetails';

const HomeRouter: React.FC = () => {

  const renderSeriesEdit = (props: any) => <NewSeries {...props} isEditing />
  const renderIssueEdit = (props: any) => <NewIssue {...props} isEditing />

  return (
    <Switch>
      <Route path="/" exact component={LibraryPage} />
      <Route path="/series/new" component={NewSeries} />
      <Route path="/series/:seriesId" exact component={SeriesDetails} />
      <Route path="/series/:seriesId/edit" exact render={renderSeriesEdit} />
      <Route path="/series/:seriesId/new/:issueNumber" exact component={NewIssue} />
      <Route path="/series/:seriesId/issue/:issueId" exact component={IssueDetails} />
      <Route path="/series/:seriesId/issue/:issueId/edit" exact render={renderIssueEdit} />
      <Route path="/series/:seriesId/issue/:issueId/new/:pageNumber" exact component={NewPage} />
      <Route path="/series/:seriesId/issue/:issueId/page/:pageId" component={PageDetails} />
    </Switch>
  );
};

export default HomeRouter;
