// tslint:disable: jsx-no-lambda
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import { userService } from '../../services/UserService';

const Login: React.FC<RouteComponentProps> = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if(userService.isAuthenticated()) {
      props.history.push('/');
    }
  }, [props.history]);

  const login = useCallback(async (e: any) => {
    e.preventDefault();
    setErrorMessage('');
    const credentials = { email, password };
    try {
      await userService.login(credentials);
      props.history.push('/');
    } catch (e) {
      setErrorMessage(e.message);
    }
  }, [email, password, props.history]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg={6}>
          <Form onSubmit={login}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Login
            </Button>
            <Button
              variant="link"
              as={Link}
              to="/forgot"
            >
              Forgot password?
            </Button>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;