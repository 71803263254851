import React, { useCallback, useState } from 'react';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { OPTIONS_TYPES, VARIABLE_TYPES } from '../../constants';
import { emptyTranslated } from '../../declarations';
import { ReactComponent as DeleteIcon } from '../../images/garbage.svg';
import feathersApp from '../../utils/feathers';
import InputWithLanguages from '../InputWithLanguages';
import './styles.scss';

interface ValidateOptionsProps {
  variableType: string;
  options: any[];
  replaceOption: (index: number, value?: any) => void;
  setOptions: (options: any) => void;
  optionsType: string;
  setOptionsType: (e: any) => void;
}

const VariableOptions: React.FC<ValidateOptionsProps> = props => {
  const { options, variableType, replaceOption, setOptions, optionsType, setOptionsType } = props;
  const [selectedImage, setSelectedImage] = useState<string>();
  const [loading, setLoading] = useState<boolean>();

  const selectImage = useCallback((url: string) => {
    setSelectedImage(selected => (url === selected ? undefined : url));
  }, []);

  const uploadImage = useCallback(
    async (e: any, i: number) => {
      const files: File[] = [...e.target.files];
      const data = new FormData();
      files.forEach(file => {
        data.append('images', file);
      });
      setLoading(true);
      const uploads = await feathersApp.service('uploads').create(data);
      replaceOption(i, { imageUrl: uploads[0] });
      setLoading(false);
    },
    [replaceOption],
  );

  const addOption = useCallback(() => {
    if (optionsType === OPTIONS_TYPES.text) {
      setOptions([...options, { name: emptyTranslated }]);
    }
    if (optionsType === OPTIONS_TYPES.img) {
      setOptions([...options, { imageUrl: undefined }]);
    }
  }, [options, optionsType, setOptions]);

  return (
    <>
      {variableType === VARIABLE_TYPES.select ? (
        <>
          <Form.Group controlId="formGridType">
            <Form.Label>Options type</Form.Label>
            <Form.Control as="select" required value={optionsType} onChange={setOptionsType}>
              <option value="">Select variable type</option>
              <option value={OPTIONS_TYPES.text}>Simple options</option>
              <option value={OPTIONS_TYPES.img}>Image options</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">Variable type is mandatory</Form.Control.Feedback>
          </Form.Group>
          {optionsType === OPTIONS_TYPES.img && loading && (
            <div className="variable-loader-wrapper">
              <Spinner animation="border" />
            </div>
          )}
          {optionsType === OPTIONS_TYPES.text
            ? options.map((option, i) => (
                <Form.Group key={i}>
                  <Form.Label>
                    Option {i + 1}
                    {options.length > 2 && (
                      <Button variant="link" type="button" onClick={() => replaceOption(i)}>
                        remove
                      </Button>
                    )}
                  </Form.Label>
                  <InputWithLanguages
                    value={option.name || emptyTranslated}
                    onChange={t => replaceOption(i, t)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Option name is mandatory
                  </Form.Control.Feedback>
                </Form.Group>
              ))
            : optionsType === OPTIONS_TYPES.img &&
              options.map((option, i) => {
                return (
                  <Form.Group key={i}>
                    <Form.Label>
                      Option {i + 1}
                      {options.length > 2 && (
                        <Button variant="link" type="button" onClick={() => replaceOption(i)}>
                          remove
                        </Button>
                      )}
                    </Form.Label>
                    {option.imageUrl ? (
                      <Form.Group className="add-asset-modal_asset-from">
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={option.name}
                            required
                            onChange={(e: any) =>
                              replaceOption(i, { ...option, name: e.target.value })
                            }
                          />
                          <DeleteIcon
                            className="add-asset-modal_asset-from_delete-icon"
                            onClick={() => replaceOption(i)}
                          />
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                          Asset name is mandatory
                        </Form.Control.Feedback>

                        <img
                          alt="layer"
                          className={`add-asset-modal_img-preview ${
                            option === selectedImage ? 'add-asset-modal_img-preview--selected' : ''
                          }`}
                          src={option.imageUrl}
                          // tslint:disable-next-line: jsx-no-lambda
                          onClick={() => selectImage(option)}
                        />
                      </Form.Group>
                    ) : (
                      <Form.Control
                        required
                        value={option.imageUrl}
                        type="file"
                        onChange={(e: any) => uploadImage(e, i)}
                        accept="image/*"
                      />
                    )}
                  </Form.Group>
                );
              })}
          {options.length > 0 && (
            <Button variant="light" type="button" onClick={() => addOption()}>
              Add option
            </Button>
          )}
        </>
      ) : null}
    </>
  );
};

export default VariableOptions;
