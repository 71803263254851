import React from 'react';
import { Link } from 'react-router-dom';
import { Series } from '../../../declarations';
import './styles.scss';

export interface LibrarySeriesCardProps {
  series: Series;
};

const LibrarySeriesCard: React.FC<LibrarySeriesCardProps> = (props) => {
  const { series } = props;

  return (
    <Link className="series-card" to={`/series/${series.id}`}>
      <div >
        <img
          src={series.coverUrl || 'https://cdn.shopify.com/s/files/1/0499/0581/products/HoC_Hulk_Cvr_A_1024x1024.jpg?v=1480890120'}
          className="series-card_cover"
          alt="Series cover"
        />
        <div className="series-card_info">
          <p className="series-card_info_title">{series.title.default}</p>
          <p>{series.issuesNumber} issues</p>
        </div>
      </div>
    </Link>
  );
};

export default LibrarySeriesCard;
