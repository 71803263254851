import React, { useCallback, useMemo } from 'react';
import { Pagination } from 'react-bootstrap';

export interface PaginationItemsPropsType {
  pagination: {
    limit: number;
    skip: number;
    total: number;
  };
  selectPage: (n: number) => void;
}

const PaginationItems: React.FC<PaginationItemsPropsType> = (props) => {
  const { pagination, selectPage } = props;
  
  const pages = Math.ceil(pagination.total / pagination.limit);
  const activePage = pagination.skip / pagination.limit + 1;
  const selectPreviousHandler = useCallback(() => {
    if (activePage > 1) {
      selectPage(activePage - 1);
    }
  }, [activePage, selectPage]);

  const selectNextHandler = useCallback(() => {
    if (activePage < pages) {
      selectPage(activePage + 1);
    }
  }, [activePage, pages, selectPage]);

  const items = useMemo(() => {
    const temp = [];
    for (let i = 1; i <= pages; i++) {
      const selectPageHandler = () => {
        selectPage(i);
      };
      temp.push(<Pagination.Item onClick={selectPageHandler} key={i} active={i === activePage} >{i}</Pagination.Item>)
    }
    return temp;
  }, [pages, activePage, selectPage])

  return (
    <>
      <Pagination.Prev onClick={selectPreviousHandler} disabled={activePage === 1} key="pagination-prev" />
      {items}
      <Pagination.Next onClick={selectNextHandler} disabled={activePage === pages} key="pagination-next" />
    </>
  );
};

export default PaginationItems;