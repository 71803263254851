import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { userService } from '../../services/UserService';

const PrivateRoute: React.FC<RouteProps & { component: React.ComponentType<any> }> = ({ component: ProtectedComponent, ...rest }) => {
  
  const getComponent = (props: any) => {
    return userService.isAuthenticated() ? <ProtectedComponent {...props} /> : <Redirect to="/login" />
  }
  
  return (
    <Route {...rest} render={getComponent} />
  );
};

export default PrivateRoute;