import React from 'react';
import Footer from '../../components/Footer';
import HomeRouter from '../../Routing/HomeRouter';
import HomeHeader from './HomeHeader';
import './styles.scss';

const Home: React.FC = () => {
  return (
    <div>
      <HomeHeader />
      <div className="content">
        <HomeRouter />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
