import React, { useCallback, useState } from 'react';
import { Button, Container, Form, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles.scss';

interface LibraryHeaderPropsType {
  submitSearch: (s: string) => void;
  searchString: string;
}

const LibraryHeader: React.FC<LibraryHeaderPropsType> = (props) => {
  const { submitSearch, searchString: defaultSearchString } = props;

  const [searchString, setSeachString] = useState<string>(defaultSearchString);

  const onSearchSubmit = useCallback<(e: any) => void>((e) => {
    e.preventDefault();
    submitSearch(searchString);
  }, [submitSearch, searchString]);

  const onSearchStringChange = useCallback<(e: any) => void>((e) => {
    setSeachString(e.target.value);
  }, []);

  return (
    <Container className="library-header">
      <h2>Series</h2>
      <Button
        className="library-header_create-button"
        variant="primary"
        as={Link}
        to={`/series/new`}
      >
        Create new
      </Button>
      <Form 
        onSubmit={onSearchSubmit}
        className="library-header_search-form"
      >
        <FormControl
          placeholder="Search..."
          value={searchString}
          onChange={onSearchStringChange}
        />
      </Form>
    </Container>
  );
};

export default LibraryHeader;
