export interface BaseModel {
  createdAt: string;
  updatedAt: string;
}

export interface Translated {
  default: string;
  en: string;
  es: string;
  de: string;
}

export const emptyTranslated: Translated = {
  default: '',
  en: '',
  es: '',
  de: '',
};

export interface Language extends BaseModel {
  id: number;
  name: string;
  imageUrl?: string;
}

export interface Option extends BaseModel {
  id: number;
  name: Translated;
  variableId: number;
  imageUrl?: string;
  type: string;
}

export interface Variable extends BaseModel {
  id: number;
  name: string;
  userDescription: Translated;
  options?: Option[];
  level?: 'series' | 'issue' | 'page';
  type: string;
  optionsType: string;
}

export interface Page extends BaseModel {
  id: number;
  description: string;
  number: number;
  issueId: number;
  variables?: Variable[];
  pageStatus: string;
  previewUrl?: string;
  thumbnailUrl?: string;
  languageSkipped?: boolean;
}

export interface Issue extends BaseModel {
  id: number;
  title: Translated;
  description: Translated;
  number: number;
  published: boolean;
  coverUrl: string;
  pagesNumber: number;
  seriesId: number;
  pages?: Page[];
  variables?: Variable[];
}

export interface Series extends BaseModel {
  id: number;
  title: Translated;
  description: Translated;
  coverUrl: string;
  price: number;
  published: boolean;
  issuesNumber: number;
  pageWidth: number;
  pageHeight: number;
  languages?: Language[];
  issues?: Issue[];
  variables?: Variable[];
}

export interface User {
  user: {
    id: number;
    email: string;
  };
  accessToken?: string;
}

export interface Credentials {
  email: string;
  password: string;
}

export interface Asset extends BaseModel {
  id: number;
  name: string;
  type: string;
  url: string;
  thumbnailUrl: string;
  number: number;
  options: Option[];
  pageId: number;
  languageId: number | null;
  offsetLeft: number;
  offsetTop: number;
  width: number;
  height: number;
}

export interface DragItem {
  type: string;
  id: string;
  top: number;
  left: number;
}

export interface DragAsset {
  top: number;
  left: number;
  name: string;
  id: number;
  type: string;
  typeA: string;
}
