import feathers, { HookContext } from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import axios from 'axios';
import { userService } from '../services/UserService';

const app = feathers();

const restClient = rest(window.env.apiUrl);
app.configure(restClient.axios(axios))
  .hooks({
    before: {
      all: [(context: HookContext) => {
        if(userService.isAuthenticated()) {
          if (!context.params.headers) { context.params.headers = {}; }

          context.params.headers = {
            ...context.params.headers,
            Authorization: userService.user && userService.user.accessToken
          };
        }
        return Promise.resolve(context);
      }]
    },
    // tslint:disable-next-line: object-literal-sort-keys
    error: {
      all: [(context: HookContext) => {
        const { error } = context;
        if(error.code === 401 && error.data && error.data.name === 'TokenExpiredError') {
          userService.logout();
          window.location.reload();
        } else {
          return context;
        }
      }]
    }
  });

export default app;
