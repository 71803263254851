import randomColor from 'randomcolor';
import React, { useCallback, useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { ListGroup } from 'react-bootstrap';
import { Language } from '../../../declarations';
import { LEVEL_LUMINOSITY } from '../../VariablesList/VariableCardOption';

export const SEED_MULTIPLIER = 5;

export interface LanguagesCardOptionPropTypes {
  language: Language;
  selected?: boolean;
  selectLanguage: (languageId: number) => void;
}


const LanguagesCardOption: React.FC<LanguagesCardOptionPropTypes> = props => {
  const { language, selected, selectLanguage } = props;

  const selectLanguageHandler = useCallback(() => {
    selectLanguage(language.id);
  }, [selectLanguage, language.id]);

  const color = useMemo(() => {
    return randomColor({ seed: language.id * SEED_MULTIPLIER, luminosity: LEVEL_LUMINOSITY.series });
  }, [language.id]);

  return (
    <Droppable droppableId={`language-option-${language.id}`}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <ListGroup.Item
            className={`option ${snapshot.isDraggingOver ? 'option--dragged-over' : ''}`}
            style={{
              backgroundColor: selected ? color && `${color}22` : undefined,
            }}
            onClick={selectLanguageHandler}
          >
            <p>{language.name}</p>
            <div
              className="option_color-mark"
              style={{
                backgroundColor: color,
              }}
            />
          </ListGroup.Item>
          {/* {provided.placeholder} */}
        </div>
      )}
    </Droppable> 
  );
};

export default LanguagesCardOption;
