// tslint:disable jsx-no-lambda
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { OPTIONS_TYPES, VARIABLE_TYPES } from '../../constants';
import { emptyTranslated, Option, Variable } from '../../declarations';
import { serializeTranslated } from '../../utils/serializeTranslated';
import InputWithLanguages from '../InputWithLanguages';
import VariableOptions from './VariableOptions';

export interface AddVariableModalPropsType {
  visible: boolean;
  existingVariable?: Variable;
  isAvailableTextVariables: boolean;
  isHasTextVariable?: boolean;
  onClose(
    variable?: Omit<Partial<Variable>, 'options'> & { options: Array<Partial<Option>> },
  ): void;
}

const AddVariableModal: React.FC<AddVariableModalPropsType> = props => {
  const { visible, onClose, existingVariable, isAvailableTextVariables, isHasTextVariable } = props;
  const [variable, setVariable] = useState<Partial<Variable>>({});
  const [options, setOptions] = useState<Array<Partial<Option>>>([]);
  const [formValidated, setFormValidated] = useState(false);

  const isDisableTextVariable = useMemo(() => {
    return isHasTextVariable || !isAvailableTextVariables;
  }, [isHasTextVariable, isAvailableTextVariables]);

  useEffect(() => {
    let newOptions: any = (existingVariable && existingVariable.options) || [];
    if (variable.optionsType === OPTIONS_TYPES.text) {
      if (!newOptions.length) {
        newOptions = [{ name: emptyTranslated }, { name: emptyTranslated }];
      }
    }
    if (variable.optionsType === OPTIONS_TYPES.img) {
      if (!newOptions.length) {
        newOptions = [{ imageUrl: null }, { imageUrl: null }];
      }
    }
    setOptions(newOptions);
  }, [existingVariable, variable.optionsType]);

  useEffect(() => {
    setVariable({
      id: existingVariable ? existingVariable.id : undefined,
      name: existingVariable ? existingVariable.name : '',
      userDescription: existingVariable ? existingVariable.userDescription : emptyTranslated,
      type: !isDisableTextVariable
        ? existingVariable
          ? existingVariable.type
          : ''
        : VARIABLE_TYPES.select,
      optionsType: (existingVariable && existingVariable.optionsType) || '',
    });
  }, [existingVariable, isDisableTextVariable]);

  const handleCancel = useCallback(() => {
    setFormValidated(false);
    setVariable({
      name: '',
      userDescription: emptyTranslated,
      type: isDisableTextVariable ? VARIABLE_TYPES.select : '',
    });
    setOptions([{ name: emptyTranslated }, { name: emptyTranslated }]);
    onClose();
  }, [onClose, isDisableTextVariable]);

  const handleSave = useCallback(
    (event: any) => {
      event.preventDefault();
      setFormValidated(true);
      const fullOption = OPTIONS_TYPES.text ? true : options.every((option: any) => option.name);
      const resultOption =
        variable.optionsType === OPTIONS_TYPES.text
          ? options.map(option => serializeTranslated(option, ['name']))
          : variable.optionsType === OPTIONS_TYPES.img
          ? options.map(option => ({ ...option, type: OPTIONS_TYPES.img }))
          : null;
      if (!event.currentTarget.checkValidity() || !fullOption) {
        return;
      }
      onClose({
        ...serializeTranslated(variable, ['userDescription']),
        options: resultOption,
        type: variable.type,
      });

      setFormValidated(false);
      setOptions([{ name: emptyTranslated }, { name: emptyTranslated }]);
      setVariable({
        name: '',
        userDescription: emptyTranslated,
        type: isDisableTextVariable ? VARIABLE_TYPES.select : '',
      });
    },
    [variable, options, onClose, isDisableTextVariable],
  );

  const replaceOption = (index: number, value?: any) => {
    const temp = [...options];
    if (value === undefined) {
      if (temp.length <= 2) {
        if (variable.optionsType === OPTIONS_TYPES.text) {
          temp.splice(index, 1, { ...temp[index], name: emptyTranslated });
        }
        if (variable.optionsType === OPTIONS_TYPES.img) {
          temp.splice(index, 1, { imageUrl: undefined });
        }
      }
    } else {
      if (variable.optionsType === OPTIONS_TYPES.text) {
        temp.splice(index, 1, { ...temp[index], name: value });
      }
      if (variable.optionsType === OPTIONS_TYPES.img) {
        temp.splice(index, 1, value);
      }
    }
    setOptions(temp);
  };

  return (
    <Modal show={visible} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{!!existingVariable ? 'Edit variable' : 'New Variable'}</Modal.Title>
      </Modal.Header>
      <Form noValidate validated={formValidated} onSubmit={handleSave}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              required
              placeholder="Enter name"
              onChange={(e: any) => setVariable({ ...variable, name: e.target.value })}
              value={variable.name}
            />
            <Form.Control.Feedback type="invalid">Name is mandatory</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>User description</Form.Label>
            <InputWithLanguages
              value={variable.userDescription || emptyTranslated}
              onChange={t => setVariable({ ...variable, userDescription: t })}
            />
            <Form.Control.Feedback type="invalid">
              User description is mandatory
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formGridType">
            <Form.Label>Variable type</Form.Label>
            <Form.Control
              as="select"
              required
              disabled={isDisableTextVariable || !!(existingVariable && existingVariable.type)}
              value={isDisableTextVariable ? VARIABLE_TYPES.select : variable.type}
              onChange={(e: any) => setVariable({ ...variable, type: e.target.value })}
            >
              <option value="">Select variable type</option>
              <option value={VARIABLE_TYPES.text}>Character Name variable</option>
              <option value={VARIABLE_TYPES.select}>Simple variable</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">Variable type is mandatory</Form.Control.Feedback>
          </Form.Group>
          <VariableOptions
            variableType={variable.type || ''}
            options={options}
            setOptionsType={(e: any) => setVariable({ ...variable, optionsType: e.target.value })}
            optionsType={variable.optionsType || ''}
            replaceOption={replaceOption}
            setOptions={setOptions}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddVariableModal;
