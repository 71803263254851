import React, { useCallback, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import feathersApp from '../../utils/feathers';
import './styles.scss';

export interface InviteUserModalPropsType {
  visible: boolean;
  close(): void;
}

const InviteUserModal: React.FC<InviteUserModalPropsType> = props => {
  const { visible, close } = props;
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const setEmailHandler = useCallback((e: any) => {
    setEmail(e.target.value);
  }, []);

  const handleCancel = useCallback(() => {
    close();
  }, [close]);

  const handleInvite = useCallback(async () => {
    setError('');
    if (!email) {
      setError('Email is empty');
      return;
    }
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setError('Emails not valid');
      return;
    }
    setLoading(true);
    try {
      await feathersApp.service('users').create({
        email,
        password: Math.random().toString(),
        permissions: 'admin',
      });
      close();
    } catch (e) {
      setError(e.errors[0].message);
    }
    setLoading(false);
  }, [close, email]);

  return (
    <Modal className="invite-user-modal" show={visible} onHide={handleCancel}>
      {loading && (
        <div className="invite-user-modal_loader-wrapper">
          <Spinner animation="border" />
        </div>
      )}
      <Modal.Header closeButton>
        <Modal.Title>New Asset</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={setEmailHandler}
            value={email}
          />
          {error && <p className="invite-user-modal_error">{error}</p>}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleInvite}>Invite</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InviteUserModal;
