// tslint:disable jsx-no-lambda
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Container, Form } from 'react-bootstrap';
import { useLoads } from 'react-loads';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/Breadcrumbs';
import ErrorModal from '../../components/ErrorModal';
import InputWithLanguages from '../../components/InputWithLanguages';
import { emptyTranslated, Language, Series } from '../../declarations';
import feathersApp from '../../utils/feathers';
import { serializeTranslated } from '../../utils/serializeTranslated';
import FileUploadInput from '../../components/FIleUploadInput';
import './styles.scss';

export interface NewSeriesPropsType extends RouteComponentProps<{ seriesId?: string }> {
  isEditing?: boolean;
};

const NewSeries: React.FC<NewSeriesPropsType> = (props) => {

  const [series, setSeries] = useState<Partial<Series & { languagesIds: number[] }>>({
    coverUrl: '',
    description: emptyTranslated,
    languagesIds: [],
    title: emptyTranslated,
    pageWidth: 168,
    pageHeight: 257,
  });
  const [languages, setLanguages] = useState<Language[]>([]);
  const [error, setError] = useState('');
  const [formValidated, setFormValidated] = useState(false);
  const clearError = useCallback(() => setError(''), []);
  const [coverError, setCoverError] = useState('');
  useEffect(() => {
    setCoverError('');
  }, [series.coverUrl]);

  const getLanguages = useCallback(async () => {
    try {
      const { data } = await feathersApp.service('languages').find();
      setLanguages(data);
    } catch (e) {
      setError(e.message);
    }
  }, []);
  
  const { isPending } = useLoads(getLanguages, {}, []);

  const validate = useCallback((event: any) => {
    setFormValidated(true);
    const formValid = event.currentTarget.checkValidity();
    const coverValid = !!series.coverUrl;
    if (!coverValid) {
      setCoverError('Cover is required');
    }
    return formValid && coverValid;
  }, [series.coverUrl]);

  const createSeries = useCallback(async (event: any) => {
    event.preventDefault();
    if(!validate(event)) {
      return;
    }
    try {
      const serialized = serializeTranslated(series, ['title', 'description']);
      const createdSeries = await feathersApp.service('series').create(serialized);
      if (createdSeries && createdSeries.id) {
        props.history.push(`/series/${createdSeries.id}`);
      } else {
        props.history.push(`/`);
      }
     
    } catch (e) {
      setError(e.message);
    }
  }, [series, props.history, validate]);

  const updateSeries = useCallback(async (event: any) => {
    event.preventDefault();
    if(!validate(event)) {
      return;
    }
    try {
      const { id: seriesId, ...seriesData } = series;
      const serialized = serializeTranslated(seriesData, ['title', 'description']);
      await feathersApp.service('series').update(seriesId, serialized);
      props.history.push(`/series/${seriesId}`);
    } catch (e) {
      setError(e.message);
    }
  }, [series, props.history, validate]);

  const deleteSeries = useCallback(async () => {
    if(!series.id) {
      return;
    }
    try {
      await feathersApp.service('series').remove(series.id);
      props.history.push(`/`);
    } catch (e) {
      setError(e.message);
    }
  }, [series.id, props.history]);

  const getSeries = useCallback(async () => {
    const { seriesId } = props.match.params;
    if(!seriesId) {
      return;
    }
    try {
      const data = await feathersApp.service('series').get(seriesId, { query: { includeLanguages: true } });
      data.languagesIds = data.languages.map((l: Language) => l.id);
      setSeries(data);
    } catch (e) {
      setError(e.message);
    }
  }, [props.match.params]); 

  const { isPending: isPendingSeries, load: loadSeries, isResolved: isSeriesLoaded } = useLoads(getSeries, { defer: true }, [props.isEditing]);
  if (props.isEditing && !isPendingSeries && !isSeriesLoaded) {
    loadSeries();
  }

  const breadcrumbsItems = useMemo(() => {
    const items: BreadcrumbsItem[] = [
      {
        link: '/',
        text: 'Library',
      },
    ];

    if(props.isEditing) {
      items.push({
        link: `/series/${series.id}`,
        text: 'Series details',
      });
    }

    items.push({
      active: true,
      text: props.isEditing ? 'Edit series' : 'New series',
    });
    return items;
  }, [props.isEditing, series.id]);

  if(isPending || isPendingSeries) {
    return (<div>Loading...</div>)
  }

  return (
    <>
    <Breadcrumbs items={breadcrumbsItems} />
    <Container>
      <h2>{props.isEditing ? 'Edit Series' : 'New Series'}</h2>
      <Form noValidate validated={formValidated} onSubmit={props.isEditing ? updateSeries : createSeries}>
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <InputWithLanguages value={series.title || emptyTranslated} onChange={(t) => setSeries({ ...series, title: t })} />
          <Form.Control.Feedback type="invalid">
            Title is mandatory
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <InputWithLanguages isTextArea value={series.description || emptyTranslated} onChange={(t) => setSeries({ ...series, description: t })} />
          <Form.Control.Feedback type="invalid">
            Description is mandatory
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Price</Form.Label>
          <Form.Control
            type="number"
            required
            min={1}
            step={0.01}
            placeholder="Enter price"
            value={series.price + ''}
            onChange={(e: any) => setSeries({ ...series, price: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">
            {!series.price ? 'Price is mandatory' : 'Invalid price'}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="new-series_cover-form-group">
          <Form.Label>Cover</Form.Label>
          {series.coverUrl && <img src={series.coverUrl} className="new-series_cover-form-group_image" alt="cover" />}
          <FileUploadInput
            multiple={false}
            onError={setError}
            onUpload={(urls) => setSeries({ ...series, coverUrl: urls[0] })}
          />
          {coverError && <div className="invalid-feedback-cover">{coverError}</div> }
        </Form.Group>
        <Form.Group>
          <Form.Label>Number of issues</Form.Label>
          <Form.Control
            type="number"
            required
            min={1}
            placeholder="Enter number of issues"
            value={series.issuesNumber + ''}
            onChange={(e: any) => setSeries({ ...series, issuesNumber: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">
            {!series.issuesNumber ? 'Number of issues is mandatory' : 'Number of issues is invalid'}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Page width (mm)</Form.Label>
          <Form.Control
            type="number"
            required
            min={1}
            placeholder="Enter page width in mm"
            value={series.pageWidth + ''}
            onChange={(e: any) => setSeries({ ...series, pageWidth: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">
            {!series.pageWidth ? 'Page width is mandatory' : 'Invalid page width'}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Page height (mm)</Form.Label>
          <Form.Control
            type="number"
            required
            min={1}
            placeholder="Enter page height in mm"
            value={series.pageHeight + ''}
            onChange={(e: any) => setSeries({ ...series, pageHeight: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">
            {!series.pageHeight ? 'Page height is mandatory' : 'Invalid page height'}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Languages</Form.Label>
          <Select
            closeMenuOnSelect={false}
            defaultValue={languages.filter(l => series.languagesIds && series.languagesIds.includes(l.id)).map(l => ({ value: l.id, label: l.name }))}
            isMulti
            options={languages.map(l => ({ value: l.id, label: l.name }))}
            onChange={(options: any) => {
              setSeries({ ...series, languagesIds: options.map((o: any) => o.value) })}
            }
          />
        </Form.Group>
        <ButtonGroup>
          {props.isEditing && <Button type="button" onClick={deleteSeries} variant="danger">Delete</Button>}
          <Button type="submit">{props.isEditing ? 'Update' : 'Create'}</Button>
        </ButtonGroup>
      </Form>
      <ErrorModal message={error} visible={!!error} onClose={clearError} />
    </Container>
    </>
  );
};

export default NewSeries;
