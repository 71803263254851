// tslint:disable: jsx-no-lambda
import React, { useCallback, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import ErrorModal from '../../components/ErrorModal';
import { Page } from '../../declarations';
import feathersApp from '../../utils/feathers';

const NewPage: React.FC<RouteComponentProps<{ seriesId: string, issueId: string, pageNumber: string }>> = (props) => {
  const { seriesId, issueId, pageNumber } = props.match.params;
  const [page, setPage] = useState<Partial<Page>>({
    description: '',
    issueId: parseInt(issueId, 10),
    number: parseInt(pageNumber, 10),
  });
  const [error, setError] = useState('');
  const clearError = useCallback(() => setError(''), []);
  
  const createPage = useCallback(async (event: any) => {
    event.preventDefault();
    try {
      const createdPage = await feathersApp.service('pages').create(page);
      if(createdPage && createdPage.id) {
        props.history.push(`/series/${seriesId}/issue/${issueId}/page/${createdPage.id}`);
      } else {
        props.history.push(`/series/${seriesId}/issue/${issueId}`);
      }
    } catch (e) {
      setError(e.message);
    }
  }, [page, props.history, seriesId, issueId]);

  return (
    <>
    <Breadcrumbs items={[
      {
        link: '/',
        text: 'Library'
      },
      {
        link: `/series/${seriesId}`,
        text: 'Series details',
      },
      {
        link: `/series/${seriesId}/issue/${issueId}`,
        text: 'Issue details',
      },
      {
        active: true,
        text: 'New page',
      },
    ]} />
    <Container>
      <h2>New Page</h2>
      <Form onSubmit={createPage}>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            type="text"
            placeholder="Enter description"
            onChange={(e: any) => setPage({ ...page, description: e.target.value })}
          />
        </Form.Group>
        <Button type="submit">Create</Button>
      </Form>
      <ErrorModal message={error} visible={!!error} onClose={clearError} />
    </Container>
    </>
  );
};

export default NewPage;
