import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import './styles.scss';

export interface IssueCardEmptyPropsType extends RouteComponentProps<{ seriesId: string }> {
  issueNumber: number;
}

const IssueCardEmpty: React.FC<IssueCardEmptyPropsType> = (props) => {
  const { issueNumber } = props;
  const { seriesId } = props.match.params;

  return (
    <Link
      className="issue-card-empty"
      to={`/series/${seriesId}/new/${issueNumber}`}
    >
        Issue {issueNumber}
    </Link>
  );
}

export default withRouter(IssueCardEmpty);
