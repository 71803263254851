import React, { useCallback, useState, useMemo } from 'react';
import { Button, ButtonToolbar, Container, Row } from 'react-bootstrap';
import { useLoads } from 'react-loads';
import { Link, RouteComponentProps } from 'react-router-dom';
import AddVaribleModal from '../../components/AddVariableModal';
import Breadcrumbs from '../../components/Breadcrumbs';
import ErrorModal from '../../components/ErrorModal';
import VariablesList from '../../components/VariablesList';
import { Series } from '../../declarations';
import feathersApp from '../../utils/feathers';
import IssuesGrid from './IssuesGrid';
import './styles.scss';

interface SeriesDetailsRouteParams {
  seriesId: string;
}

const SeriesDetails: React.FC<RouteComponentProps<SeriesDetailsRouteParams>> = props => {
  const { seriesId } = props.match.params;
  const [series, setSeries] = useState<Partial<Series>>({});
  const [modalVisible, setModalVisible] = useState(false);
  const [error, setError] = useState('');
  const clearError = useCallback(() => setError(''), []);

  const showModal = () => setModalVisible(true);

  const getSeries = useCallback(async () => {
    try {
      const data = await feathersApp.service('series').get(parseInt(seriesId, 10), {
        query: {
          includeIssues: true,
          includeLanguages: true,
          includeVariables: true,
        },
      });
      setSeries(data);
    } catch (e) {
      setError(e.message);
    }
  }, [seriesId]);

  const isHasTextVariable = useMemo(() => {
    return series && series.variables && !!series.variables.find(v => v.type === 'TEXT');
  }, [series]);

  const saveVariable = useCallback(
    async (variable?: any) => {
      try {
        if (variable && !variable.id) {
          const data = {
            ...variable,
            seriesId,
          };
          const createdVariable = await feathersApp
            .service('variables')
            .create(data, { query: { includeOptions: true } });
          const newVars = series.variables
            ? [...series.variables, createdVariable]
            : [createdVariable];
          setSeries({ ...series, variables: newVars });
        }
        if (variable && variable.id) {
          await feathersApp.service('variables').patch(variable.id, variable);
          updateSeries();
        }
        setModalVisible(false);
      } catch (e) {
        setError(e.message);
      }
    },
    [seriesId, series],
  );

  const deleteVariable = useCallback(
    async (variableId: number) => {
      try {
        await feathersApp.service('variables').remove(variableId);
        if (!series.variables) {
          return;
        }
        setSeries({ ...series, variables: series.variables.filter(v => v.id !== variableId) });
      } catch (e) {
        setError(e.message);
      }
    },
    [series],
  );

  const publishSeries = useCallback(async () => {
    try {
      await feathersApp.service('series').patch(seriesId, { published: true });
      setSeries({ ...series, published: true });
    } catch (e) {
      setError(e.message);
    }
  }, [seriesId, series]);

  const unpublishSeries = useCallback(async () => {
    try {
      await feathersApp.service('series').patch(seriesId, { published: false });
      setSeries({ ...series, published: false });
    } catch (e) {
      setError(e.message);
    }
  }, [seriesId, series]);

  const { isPending, load: updateSeries } = useLoads(getSeries, {}, [seriesId]);

  if (isPending) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Breadcrumbs
        items={[
          {
            link: '/',
            text: 'Library',
          },
          {
            active: true,
            text: 'Series details',
          },
        ]}
      />
      <Container className="series-details">
        <Row>
          <h2>{series.title && series.title.default}</h2>
        </Row>
        <Row>
          <div className="series-details_cover-wrapper">
            <img src={series.coverUrl} alt="cover" />
          </div>
          <div>
            <div>
              <h4>Synopsis</h4>
              <p className="series-details_description">
                {series.description && series.description.default}
              </p>
            </div>
            <div>
              <h4>Issues</h4>
              <p>{series.issuesNumber}</p>
            </div>
            <div>
              <h4>Price</h4>
              <p>{series.price}</p>
            </div>
            {!!series.languages && !!series.languages.length && (
              <div>
                <h4>Languages</h4>
                <p>{series.languages.map(l => l.name).join(', ')}</p>
              </div>
            )}
            <div>
              <h4>Page dimensions</h4>
              <p>{`${series.pageWidth}x${series.pageHeight}mm`}</p>
            </div>
            {!series.published ? (
              <>
                <ButtonToolbar>
                  <Button className="series-details_button" onClick={showModal}>
                    Add variable
                  </Button>
                  <Button
                    className="series-details_button"
                    as={Link}
                    to={`/series/${seriesId}/edit`}
                  >
                    Edit
                  </Button>
                  <Button className="series-details_button" onClick={publishSeries}>
                    Publish
                  </Button>
                </ButtonToolbar>
                {modalVisible && (
                  <AddVaribleModal
                    visible={modalVisible}
                    onClose={saveVariable}
                    isAvailableTextVariables={true}
                    isHasTextVariable={isHasTextVariable}
                  />
                )}
              </>
            ) : (
              <div>
                <h4>Series is published</h4>
                <Button className="series-details_button" onClick={unpublishSeries}>
                  Unpublish
                </Button>
              </div>
            )}
          </div>
        </Row>
        <Row className="series-details_issues">
          <IssuesGrid issuesNumber={series.issuesNumber || 0} issues={series.issues || []} />
        </Row>
        <Row>
          <VariablesList
            variables={series.variables || []}
            deleteVariable={deleteVariable}
            disabled={series.published}
            updateVariable={saveVariable}
            isAvailableTextVariables={true}
          />
        </Row>
        <ErrorModal message={error} visible={!!error} onClose={clearError} />
      </Container>
    </>
  );
};

export default SeriesDetails;
