import React, { useCallback, useRef, useState } from 'react';
import { Card, Collapse, Dropdown, ListGroup } from 'react-bootstrap';
import { Variable } from '../../../declarations';
import { useClickOutside } from '../../../utils/useClickOutside';
import AddVariableModal from '../../AddVariableModal';
import { ReactComponent as MoreIcon } from '../../AssetsList/AssetsListItem/Options.svg';
import VariableCardOption from '../VariableCardOption';
import { ReactComponent as ChevronIcon } from './Big_arrow.svg';
import './styles.scss';

export interface VariableCardPropTypes {
  variable: Variable;
  selectedOptionsIds?: number[];
  toggleOptionSelected?: (optionId: number, variableId: number) => void;
  deleteVariable?: (variableId: number) => void;
  updateVariable?: (variable: any) => void;
  skipVariable?: (variableId: number) => void;
  includeVariable?: (variableId: number) => void;
  skipped?: boolean;
  disabled?: boolean;
  isAvailableTextVariables: boolean;
}

const VariableCard: React.FC<VariableCardPropTypes> = props => {
  const {
    variable,
    selectedOptionsIds = [],
    toggleOptionSelected,
    deleteVariable,
    skipVariable,
    includeVariable,
    skipped,
    disabled,
    updateVariable,
    isAvailableTextVariables,
  } = props;
  const [visible, setVisible] = useState<boolean>(!skipped);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const showEdit = useCallback((e: any) => {
    e.stopPropagation();
    setDropdownVisible(false);
    setEditModalVisible(true);
  }, []);

  const optionsContainerRef = useRef(null);
  const dropdownRef = useRef(null);
  const hideDropdown = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (dropdownVisible) {
        setDropdownVisible(false);
      }
    },
    [dropdownVisible],
  );

  useClickOutside(dropdownRef, hideDropdown);

  const getToggleOptionHandler = (id?: number) => {
    return () => {
      if (!id || !toggleOptionSelected) {
        return;
      }
      toggleOptionSelected(id, variable.id);
    };
  };

  const toggleVisible = useCallback(() => {
    if (skipped) {
      return;
    }
    setVisible(!visible);
  }, [skipped, visible]);

  const skipVariableHandler = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (skipVariable) {
        skipVariable(variable.id);
      }
      setVisible(false);
      setDropdownVisible(false);
    },
    [variable.id, skipVariable],
  );

  const includeVariableHandler = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (includeVariable) {
        includeVariable(variable.id);
      }
      setDropdownVisible(false);
    },
    [variable.id, includeVariable],
  );

  const deleteVariableHandler = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (!deleteVariable) {
        return;
      }
      deleteVariable(variable.id);
      setDropdownVisible(false);
    },
    [variable.id, deleteVariable],
  );

  const toggleDropdown = useCallback(
    (e: any) => {
      e.stopPropagation();
      setDropdownVisible(!dropdownVisible);
    },
    [dropdownVisible],
  );

  const autoscrollDropdown = useCallback(() => {
    if (!optionsContainerRef.current) {
      return;
    }
    // @ts-ignore
    optionsContainerRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  }, [optionsContainerRef]);

  const onEditModalClose = (editedVariable: any) => {
    if (updateVariable) {
      updateVariable(editedVariable);
    }
    setEditModalVisible(false);
  };

  return (
    <Card className={skipped ? 'variable-card variable-card--skipped' : 'variable-card'}>
      <Card.Header onClick={toggleVisible} className="variable-card_header">
        <div className="variable-card_header_title-wrapper">
          <Card.Title
            className={
              skipped ? 'variable-card_title variable-card_title--skipped' : 'variable-card_title'
            }
          >
            {variable.name}
          </Card.Title>
        </div>
        <div className="variable-card_header_icons-wrapper">
          {!skipped && (
            <ChevronIcon
              className={visible ? 'arrow-icon arrow-icon--open' : 'arrow-icon arrow-icon--close'}
            />
          )}
          {!disabled && (
            <>
              <div className="more-background" onClick={toggleDropdown}>
                <MoreIcon className="more-icon" />
              </div>
              <Dropdown className="variable-card_dropdown" show={dropdownVisible} ref={dropdownRef}>
                <Dropdown.Menu>
                  {skipVariable && !skipped && (
                    <Dropdown.Item onClick={skipVariableHandler}>Skip</Dropdown.Item>
                  )}
                  {skipped && (
                    <Dropdown.Item onClick={includeVariableHandler}>Include</Dropdown.Item>
                  )}
                  {skipVariable && deleteVariable && <Dropdown.Divider />}
                  {deleteVariable && (
                    <Dropdown.Item onClick={deleteVariableHandler}>Delete</Dropdown.Item>
                  )}
                  {(!variable.level || variable.level === 'page') && (
                    <Dropdown.Item onClick={showEdit}>Edit</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </div>
      </Card.Header>
      <Collapse in={visible} onEntered={autoscrollDropdown}>
        <ListGroup ref={optionsContainerRef}>
          <ListGroup.Item className="variable-card_user-description">
            {variable.userDescription.default}
          </ListGroup.Item>
          {variable.options &&
            variable.options.map((option, i) => (
              <VariableCardOption
                key={option.id}
                option={option}
                selected={!!option.id && selectedOptionsIds.includes(option.id)}
                onOptionClick={getToggleOptionHandler(option.id)}
                level={variable.level}
              />
            ))}
        </ListGroup>
      </Collapse>
      {editModalVisible && (
        <AddVariableModal
          visible={editModalVisible}
          onClose={onEditModalClose}
          existingVariable={variable}
          isAvailableTextVariables={isAvailableTextVariables}
        />
      )}
    </Card>
  );
};

export default VariableCard;
