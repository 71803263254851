import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import { userService } from '../../services/UserService';
import feathersApp from '../../utils/feathers';

const ForgotPassword: React.FC<RouteComponentProps> = (props) => {
  useEffect(() => {
    if(userService.isAuthenticated()) {
      props.history.push('/');
    }
  }, [props.history]);

  const [email, setEmail] = useState('');
  const [sendedEmail, setSendedEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState();

  const setEmailHandler = useCallback((e: any) => {
    setEmail(e.target.value);
  }, []);

  const sendResetEmail = useCallback(async (e: any) => {
    e.preventDefault();
    if(!email) {
      setErrorMessage('Email cannot be empty');
      return;
    }
    try {
      await feathersApp.service('authmanagement').create({
        action: 'sendResetPwd',
        value: {
          email,
        },
      });
      setSendedEmail(email);
    } catch (e) {
      setErrorMessage(e.message);
    }
  }, [email]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg={6}>
          {!sendedEmail ? (
            <Form onSubmit={sendResetEmail}>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={setEmailHandler}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Send reset email
              </Button>
              <Button
                variant="link"
                as={Link}
                to="/login"
              >
                Login
              </Button>
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            </Form>
          ) : (
            <div>
              Verification email was sent.
            </div>
          )}
          
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
