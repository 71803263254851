import React from 'react';
import { Row } from 'react-bootstrap';
import { Page } from '../../../declarations';
import PageCard from '../PageCard';
import PageCardEmpty from '../PageCardEmpty';
import './styles.scss';

export interface PagesGridPropsType {
  pages: Page[];
  pagesNumber: number;
}

const PagesGrid: React.FC<PagesGridPropsType> = (props) => {
  const { pages, pagesNumber } = props;

  const empty = new Array(pagesNumber).fill(0).map((_, i) => i+1);

  const getPage = (pageNumber: number) => {
    const page = pages.find(p => p.number === pageNumber);
    if(!page) {
      return <PageCardEmpty key={pageNumber} pageNumber={pageNumber} />
    }

    return <PageCard key={pageNumber} page={page} />
  }

  return (
    <div className="pages-grid">
      <Row>
        <h2>Pages</h2>
      </Row>
      <Row>
        {empty.map(getPage)}
      </Row>
    </div>
  );
};

export default PagesGrid;
