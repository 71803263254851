// tslint:disable: jsx-no-lambda
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Container, Form } from 'react-bootstrap';
import { useLoads } from 'react-loads';
import { RouteComponentProps } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/Breadcrumbs';
import ErrorModal from '../../components/ErrorModal';
import InputWithLanguages from '../../components/InputWithLanguages';
import { emptyTranslated, Issue } from '../../declarations';
import feathersApp from '../../utils/feathers';
import { serializeTranslated } from '../../utils/serializeTranslated';
import FileUploadInput from '../../components/FIleUploadInput';
import './styles.scss';

export interface NewIssuePropsType extends RouteComponentProps<{ seriesId: string, issueNumber?: string, issueId?: string }> {
  isEditing?: boolean;
}

const NewIssue: React.FC<NewIssuePropsType> = (props) => {
  const { seriesId, issueNumber } = props.match.params;
  const [issue, setIssue] = useState<Partial<Issue>>({
    coverUrl: '',
    description: emptyTranslated,
    number: issueNumber ? parseInt(issueNumber, 10): 0,
    pagesNumber: 0,
    seriesId: parseInt(seriesId, 10),
    title: emptyTranslated,
  });
  const [formValidated, setFormValidated] = useState(false);
  const [error, setError] = useState('');
  const clearError = useCallback(() => setError(''), []);
  const [coverError, setCoverError] = useState('');
  useEffect(() => {
    setCoverError('');
  }, [issue.coverUrl]);

  const validate = useCallback((event: any) => {
    setFormValidated(true);
    const formValid = event.currentTarget.checkValidity();
    const coverValid = !!issue.coverUrl;
    if (!coverValid) {
      setCoverError('Cover is required');
    }
    return formValid && coverValid;
  }, [issue.coverUrl]);

  const createIssue = useCallback(async (event: any) => {
    event.preventDefault();
    if(!validate(event)) {
      return;
    }
    try {
      const serialized = serializeTranslated(issue, ['title', 'description']);
      const createdIssue = await feathersApp.service('issues').create(serialized);
      if(createdIssue && createdIssue.id) {
        props.history.push(`/series/${seriesId}/issue/${createdIssue.id}`);
      } else {
        props.history.push(`/series/${seriesId}`);
      }
    } catch (e) {
      setError(e.message);
    }
  }, [issue, seriesId, props.history, validate]);

  const updateIssue = useCallback(async (event: any) => {
    event.preventDefault();
    if(!validate(event)) {
      return;
    }
    try {
      const { id: issueId, ...issueData } = issue;
      const serialized = serializeTranslated(issueData, ['title', 'description']);
      await feathersApp.service('issues').update(issueId, serialized);
      props.history.push(`/series/${seriesId}/issue/${issueId}`);
    } catch (e) {
      setError(e.message);
    }
  }, [issue, seriesId, props.history, validate]);

  const deleteIssue = useCallback(async () => {
    try {
      if(!issue.id) {
        return;
      }
      await feathersApp.service('issues').remove(issue.id);
      props.history.push(`/series/${seriesId}`);
    } catch (e) {
      setError(e.message);
    }
  }, [issue.id, seriesId, props.history]);

  const getIssue = useCallback(async () => {
    try {
      const { issueId } = props.match.params;
      if(!issueId) {
        return;
      }
      const data = await feathersApp.service('issues').get(issueId);
      setIssue(data);
    } catch (e) {
      setError(e.message);
    }
  }, [props.match.params]);

  const {
    isPending: isPendingIssue,
    load: loadIssue,
    isResolved: isIssueLoaded
  } = useLoads(getIssue, { defer: true }, [props.isEditing]);

  if (props.isEditing && !isPendingIssue && !isIssueLoaded) {
    loadIssue();
  }

  const breadcrumbsItems = useMemo(() => {
    const items: BreadcrumbsItem[] = [
      {
        link: '/',
        text: 'Library',
      },
      {
        link: `/series/${seriesId}`,
        text: 'Series details'
      },
    ];
    if (props.isEditing) {
      items.push({
        link: `/series/${seriesId}/issue/${issue.id}`,
        text: 'Issue details',
      });
    }
    items.push({
      active: true,
      text: props.isEditing ? 'Edit issue' : 'New issue',
    });
    return items;
  }, [props.isEditing, issue.id, seriesId]);

  if(isPendingIssue) {
    return (<div>Loading...</div>)
  }

  return (
    <>
    <Breadcrumbs items={breadcrumbsItems} />
    <Container>
      <h2>{props.isEditing ? 'Edit Issue' : 'New Issue'}</h2>
      <Form noValidate validated={formValidated} onSubmit={props.isEditing ? updateIssue : createIssue}>
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <InputWithLanguages value={issue.title || emptyTranslated} onChange={(t) => setIssue({ ...issue, title: t })} />
          <Form.Control.Feedback type="invalid">
            Title is mandatory
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <InputWithLanguages value={issue.description || emptyTranslated} onChange={(t) => setIssue({ ...issue, description: t })} />
          <Form.Control.Feedback type="invalid">
            Description is mandatory
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="new-issue_cover-form-group">
          <Form.Label>Cover</Form.Label>
          {issue.coverUrl && <img src={issue.coverUrl} className="new-issue_cover-form-group_image" alt="cover" />}
          <FileUploadInput
            multiple={false}
            onError={setError}
            onUpload={(urls) => setIssue({ ...issue, coverUrl: urls[0] })}
          />
          {coverError && <div className="invalid-feedback-cover">{coverError}</div> }
        </Form.Group>
        <Form.Group>
          <Form.Label>Number of pages</Form.Label>
          <Form.Control
            type="number"
            required
            min={1}
            placeholder="Enter number of pages"
            value={issue.pagesNumber + ''}
            onChange={(e: any) => setIssue({ ...issue, pagesNumber: e.target.value })}
          />
          <Form.Control.Feedback type="invalid">
            Number of pages is mandatory
          </Form.Control.Feedback>
        </Form.Group>
        <ButtonGroup>
          {props.isEditing && <Button type="button" onClick={deleteIssue} variant="danger">Delete</Button>}
          <Button type="submit">{props.isEditing ? 'Update' : 'Create'}</Button>
        </ButtonGroup>
      </Form>
      <ErrorModal message={error} visible={!!error} onClose={clearError} />
    </Container>
    </>
  );
};

export default NewIssue;
