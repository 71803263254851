import { Credentials, User } from '../declarations';
import feathersApp from '../utils/feathers';

class UserService {
  public user: User | null = null;

  constructor() {
    const savedUser = localStorage.getItem('user');
    if(!savedUser) {
      return;
    }
    this.user = JSON.parse(savedUser);
  }

  public async login ({ email, password }: Credentials) {
    const user = await feathersApp.service('authentication').create({
      email,
      password,
      strategy: 'local'
    });

    if(user.user.permissions !== 'admin') {
      throw new Error('You are not allowed to login.');
    }
    
    this.user = user;
    localStorage.setItem('user', JSON.stringify(this.user));
    return this.user;
  }
  
  public async logout() {
    this.user = null;
    localStorage.removeItem('user');
  }

  public isAuthenticated() {
    return !!this.user;
  }
}

export const userService = new UserService();