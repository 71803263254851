import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Series } from '../../../declarations';
import LibrarySeriesCard from '../LibrarySeriesCard';

export interface LibrarySeriesGridProps {
  series: Series[];
}

const LibrarySeriesGrid: React.FC<LibrarySeriesGridProps> = (props) => {
  const { series } = props;

  return (
    <Container>
      <Row>
        {series.map(s => <LibrarySeriesCard key={s.id} series={s} />)}
      </Row>
    </Container>
  );
}

export default LibrarySeriesGrid;
