import React, { useCallback } from 'react';
import { Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { Page } from '../../../declarations';
import feathersApp from '../../../utils/feathers';
import './styles.scss';

export interface PageActionsPropsType {
  page: Partial<Page>;
  setStatus: (status: string) => void;
}

export const PAGE_STATUSES = {
  generationError: 'preview-generation-error',
  generationInPropgress: 'generation-in-progress',
  initial: 'initial',  
  previewGenerated: 'preview-generated',
  published: 'published',
  submitted: 'submitted',
};

const PageActions: React.FC<PageActionsPropsType> = (props) => {
  const { page, setStatus } = props;
  const { pageStatus: status } = page;
  const generatePreview = useCallback(
    async () => {
      await feathersApp.service('pdf').create({ pageId: page.id }, { query: { pagePreview: true }});
      setStatus(PAGE_STATUSES.generationInPropgress);
    },
    [page.id, setStatus],
  );

  const submitPage = useCallback(async () => {
    await feathersApp.service('pages').patch(page.id, { pageStatus: PAGE_STATUSES.submitted });
    setStatus(PAGE_STATUSES.submitted);
  }, [page.id, setStatus]);

  const unsubmitPage = useCallback(async () => {
    await feathersApp.service('pages').patch(page.id, { pageStatus: PAGE_STATUSES.previewGenerated });
    setStatus(PAGE_STATUSES.previewGenerated);
  }, [page.id, setStatus]);

  const cancelGeneration = useCallback(async () => {
    const newStatus = page.previewUrl ? PAGE_STATUSES.previewGenerated : PAGE_STATUSES.initial;
    await feathersApp.service('pages').patch(page.id, {
      pageStatus: newStatus,
    });
    setStatus(newStatus);
  }, [page.id, setStatus, page.previewUrl]);

  return (
    <>
      {status === PAGE_STATUSES.initial && (
        <Button onClick={generatePreview}>Generate preview</Button>
      )}
      {status === PAGE_STATUSES.previewGenerated && (
        <ButtonGroup>
          <Button onClick={generatePreview} variant="warning">Regenerate preview</Button>
          <Button as="a" href={page.previewUrl} target="_blank">Download preview</Button>
          <Button onClick={submitPage} variant="success">Submit</Button>
        </ButtonGroup>
      )}
      {status === PAGE_STATUSES.generationInPropgress && (
        <ButtonGroup>
          <Button onClick={cancelGeneration} variant="danger">Cancel</Button>
          <Button disabled>
            <Spinner className="page-actions_spinner" as="span" animation="border" size="sm" role="status" aria-hidden />
            <span>Generating...</span>
          </Button>
        </ButtonGroup>
      )}
      {status === PAGE_STATUSES.generationError && (
        <Button onClick={generatePreview} variant="warning">Regenerate preview</Button>
      )}
      {status === PAGE_STATUSES.submitted && (
        <ButtonGroup>
          <Button as="a" href={page.previewUrl} target="_blank">Download preview</Button>
          <Button onClick={unsubmitPage} variant="warning">Unsubmit</Button>
        </ButtonGroup>
      )}
      {status === PAGE_STATUSES.published && (
        <p>Page is pusblished</p>
      )}
    </>
  );
};

export default PageActions;
