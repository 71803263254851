import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Issue } from '../../../declarations';
import './styles.scss';

export interface IssueCardPropsType {
  issue: Issue;
}

const IssueCard: React.FC<IssueCardPropsType & RouteComponentProps<{ seriesId: string }>> = (props) => {
  const { issue } = props;
  const { seriesId } = props.match.params;
  
  return (
    <Link
      className="issue-card"
      to={`/series/${seriesId}/issue/${issue.id}`}
    >
      <img
        src={issue.coverUrl}
        alt="issue cover"
        className="issue-card_cover"
      />
      <div className="issue-card_info">
        <p>{issue.title.default}</p>
        <p>{issue.pagesNumber} pages</p>
      </div>
    </Link>
  );
};

export default withRouter(IssueCard);
