import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Page } from '../../../declarations';
import './styles.scss';

export interface PageCardPropsType extends RouteComponentProps<{ seriesId: string, issueId: string }> {
  page: Page;
}

const PageCard: React.FC<PageCardPropsType> = (props) => {
  const { page } = props;
  const { seriesId, issueId } = props.match.params;

  return (
    <Link 
      className="page-card"
      to={`/series/${seriesId}/issue/${issueId}/page/${page.id}`}
    >
      <img
        src={page.thumbnailUrl}
        alt=""
        className="page-card_cover"
      />
      <div className="page-card_info">
        <p>Page {page.number}</p>
      </div>
    </Link>
  );
};

export default withRouter(PageCard);
