import React, { useCallback, useState } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import InviteUserModal from '../../../components/InviteUserModal';
import { userService } from '../../../services/UserService';
import './styles.scss';

const HomeHeader: React.FC<RouteComponentProps> = (props) => {
  const [inviteModalVisible, setInviteModalVisible] = useState(false);

  const openInviteModal = useCallback(() => setInviteModalVisible(true), []);
  const closeInviteModal = useCallback(() => setInviteModalVisible(false), []);
  
  const logout = useCallback(() => {
    userService.logout();
    props.history.push('/login');
  }, [props.history]);

  return (
    <Navbar className="home-header">
      <Navbar.Brand as={Link} to="/">ContentCo</Navbar.Brand>
      <Navbar.Toggle aria-controls="home-navbar" />
      <Navbar.Collapse id="home-navbar">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/">Library</Nav.Link>          
        </Nav>
        <NavDropdown title={userService.user && userService.user.user.email} id="home-navbar__profile-dropdown">
          <NavDropdown.Item onClick={openInviteModal}>Invite new user</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
        </NavDropdown>
      </Navbar.Collapse>
      <InviteUserModal visible={inviteModalVisible} close={closeInviteModal} />
    </Navbar>
  );
};

export default withRouter(HomeHeader);
