import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import './styles.scss';

export interface PageCardEmptyPropsType {
  pageNumber: number;
}

const PageCardEmpty: React.FC<PageCardEmptyPropsType & RouteComponentProps<{ seriesId: string, issueId: string }>> = (props) => {
  const { pageNumber } = props;
  const { issueId, seriesId } = props.match.params;

  return (
    <Link
      className="page-card-empty"
      to={`/series/${seriesId}/issue/${issueId}/new/${pageNumber}`}
    >
        Page {pageNumber}
    </Link>
  );
}

export default withRouter(PageCardEmpty);
