import randomColor from 'randomcolor';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Card, Dropdown, Form } from 'react-bootstrap';
import { ASSETS_TYPES } from '../../../constants';
import { Asset } from '../../../declarations';
import feathersApp from '../../../utils/feathers';
import { useClickOutside } from '../../../utils/useClickOutside';
import Checkbox from '../../Checkbox';
import { SEED_MULTIPLIER as LANGUAGE_SEED_MULTIPLIER } from '../../LanguagesCard/LanguagesCardOption';
import {
  LEVEL_LUMINOSITY,
  SEED_MULTIPLIER as OPTION_SEED_MULTIPLIER,
} from '../../VariablesList/VariableCardOption';
import { ReactComponent as InvisibleIcon } from './closed_eye.svg';
import { ReactComponent as DragHandle } from './Dragdrop.svg';
import { ReactComponent as VisibleIcon } from './eye.svg';
import { ReactComponent as MoreIcon } from './Options.svg';
import './styles.scss';

export interface AssetsListItemPropsType {
  asset: Asset;
  removeAsset: (assetId: number) => void;
  unlinkAsset: (assetId: number) => void;
  index: number;
  disabled?: boolean;
  variablesLevels: { [key: number]: string };
  variablesSelected?: boolean;
  selected: boolean;
  toggleAssetSelected: (assetId: number) => void;
  visible: boolean;
  toogleVisible: (assetId: number) => void;
  selectAssetOptions: (assetId: number, selected?: boolean) => void;
  updateAssets: () => void;
}

const AssetsListItem: React.FC<AssetsListItemPropsType> = props => {
  const {
    updateAssets,
    asset,
    index,
    removeAsset,
    unlinkAsset,
    disabled,
    variablesLevels,
    variablesSelected,
    selected,
    toggleAssetSelected,
    visible,
    toogleVisible,
    selectAssetOptions,
  } = props;
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [assetName, setAssetName] = useState<string>(asset.name);
  const [isNameEditing, setIsNameEditing] = useState<boolean>(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = useCallback(
    (e: any) => {
      e.stopPropagation();
      setDropdownVisible(!dropdownVisible);
    },
    [dropdownVisible],
  );
  const hideDropdown = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (dropdownVisible) {
        setDropdownVisible(false);
      }
    },
    [dropdownVisible],
  );
  const toggleEditing = useCallback(() => {
    setIsNameEditing(!isNameEditing);
  }, [isNameEditing]);

  useClickOutside(dropdownRef, hideDropdown);

  const removeAssetHandler = useCallback(() => {
    removeAsset(asset.id);
  }, [asset.id, removeAsset]);

  const colors = useMemo(() => {
    const optionsColors = asset.options.map(option =>
      randomColor({
        luminosity: LEVEL_LUMINOSITY[variablesLevels[option.variableId]],
        seed: option.id * OPTION_SEED_MULTIPLIER,
      }),
    );
    if (!asset.languageId) {
      return optionsColors;
    }
    const languageColor = randomColor({
      luminosity: LEVEL_LUMINOSITY.series,
      seed: asset.languageId * LANGUAGE_SEED_MULTIPLIER,
    });
    return [...optionsColors, languageColor];
  }, [asset.options, variablesLevels, asset.languageId]);

  const unlinkAll = useCallback(() => {
    unlinkAsset(asset.id);
  }, [asset.id, unlinkAsset]);

  const backgroundImage = useMemo(() => {
    if (!variablesSelected) {
      return;
    }
    const parts = 100 / colors.length;
    const gradientParts = colors.map((color: string, i) => {
      return `${color}22 ${i * parts}% ${(i + 1) * parts}%`;
    });
    return 'linear-gradient(to bottom, ' + gradientParts.join(', ') + ')';
  }, [variablesSelected, colors]);

  const selectedChangeHandler = useCallback(() => {
    toggleAssetSelected(asset.id);
  }, [asset.id, toggleAssetSelected]);

  const changeVisibilityHandler = useCallback(() => {
    toogleVisible(asset.id);
  }, [toogleVisible, asset.id]);

  const selectAssetOptionsHandler = useCallback(() => {
    selectAssetOptions(
      asset.id,
      !!(asset.languageId || asset.options.length) && !variablesSelected,
    );
  }, [selectAssetOptions, asset.id, asset.languageId, asset.options.length, variablesSelected]);

  const saveAssetName = useCallback(
    async (e: any) => {
      e.preventDefault();
      await feathersApp.service('assets').patch(asset.id, { name: assetName });
      setIsNameEditing(false);
      updateAssets();
    },
    [assetName, asset.id, updateAssets],
  );

  const nameInputRef = useRef(null);
  useClickOutside(nameInputRef, saveAssetName);

  return (
    <Draggable
      isDragDisabled={disabled}
      index={index}
      key={asset.id}
      draggableId={String(asset.id)}
    >
      {(provided: any, snapshot: any) => (
        <Card
          // @ts-ignore
          ref={provided.innerRef}
          className="asset-card"
          {...provided.draggableProps}
          style={{
            ...provided.draggableProps.style,
            backgroundImage,
            // ...cardStyle
          }}
        >
          <div className="asset-card_color-marks">
            {colors.map((color, i) => (
              <div
                key={color}
                className="asset-card_color-mark"
                style={{ backgroundColor: color }}
              />
            ))}
          </div>
          <Card.Body className="asset-card_body">
            {!disabled && (
              <div {...provided.dragHandleProps} className="drag-handle">
                <DragHandle className="drag-handle_icon" />
              </div>
            )}
            {isNameEditing ? (
              <Form onSubmit={saveAssetName}>
                <Form.Control
                  ref={nameInputRef}
                  value={assetName}
                  // tslint:disable-next-line: jsx-no-lambda
                  onChange={(e: any) => setAssetName(e.target.value)}
                />
              </Form>
            ) : (
              <p className="asset-card_asset-name" onDoubleClick={toggleEditing}>
                {assetName}
              </p>
            )}
            {asset.type === ASSETS_TYPES.img && (
              <img
                className="asset-card_thumbnail"
                src={asset.thumbnailUrl}
                alt="asset thumbnail"
              />
            )}
            <div className="asset-card_icons-wrapper">
              <Checkbox
                checked={selected}
                onChange={selectedChangeHandler}
                disabled={disabled || false}
              />
              {!!(asset.languageId || asset.options.length) && variablesSelected && (
                <VisibleIcon className="eye-icon" onClick={selectAssetOptionsHandler} />
              )}
              {!!(asset.languageId || asset.options.length) && !variablesSelected && (
                <InvisibleIcon className="eye-icon" onClick={selectAssetOptionsHandler} />
              )}
              {!(asset.languageId || asset.options.length) && visible && (
                <VisibleIcon className="eye-icon" onClick={changeVisibilityHandler} />
              )}
              {!(asset.languageId || asset.options.length) && !visible && (
                <InvisibleIcon className="eye-icon" onClick={changeVisibilityHandler} />
              )}
            </div>
            {!disabled && (
              <>
                <div className="more-background" onClick={toggleDropdown}>
                  <MoreIcon className="more-icon" />
                </div>
                <Dropdown className="asset-card_dropdown" show={dropdownVisible} ref={dropdownRef}>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={unlinkAll}>Unlink</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={removeAssetHandler}>Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </Card.Body>
        </Card>
      )}
    </Draggable>
  );
};

export default AssetsListItem;
