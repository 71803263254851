// tslint:disable: jsx-no-lambda
import React from 'react';
import { Form, Tab, Tabs } from 'react-bootstrap';
import { Translated } from '../../declarations';
import './styles.scss';

interface InputWithLanguagesPropsType {
  value: Translated,
  onChange: (t: Translated) => void;
  isTextArea?: boolean
};

const InputWithLanguages: React.FC<InputWithLanguagesPropsType> = (props) => {
  const { value, onChange, isTextArea } = props;

  return (
    <Tabs id="language-input-tabs" className="tab-container">
      <Tab eventKey="en" title="EN" >
        <Form.Control
          as={ isTextArea ? 'textarea': undefined }
          // ...{isTextArea ? {as : 'textarea' }: ''}
          type="text"
          required
          placeholder="Enter title"
          value={value.en}
          onChange={(e: any) => onChange({ ...value, en: e.target.value })}
        />
      </Tab>
      <Tab eventKey="es" title="ES">
        <Form.Control
          as={ isTextArea ? 'textarea': undefined }
          type="text"
          placeholder="Enter title"
          value={value.es}
          onChange={(e: any) => onChange({ ...value, es: e.target.value })}
        />
      </Tab>
      <Tab eventKey="de" title="DE">
        <Form.Control
          as={ isTextArea ? 'textarea': undefined }
          type="text"
          placeholder="Enter title"
          value={value.de}
          onChange={(e: any) => onChange({ ...value, de: e.target.value })}
        />
      </Tab>
    </Tabs>
  );
};

export default InputWithLanguages;
