import React, { useCallback, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import feathersApp from '../../utils/feathers';
import './styles.scss';

interface FileUploadInputPropsType {
  onUpload: (urls: string[]) => void;
  onError: (message: string) => void;
  multiple: boolean;
}

const FileUploadInput: React.FC<FileUploadInputPropsType> = (props) => {
  const { onUpload, onError, multiple } = props;
  const [loading, setLoading] = useState(false);

  const uploadFiles = useCallback(async (e: any) => {
    try {
      setLoading(true);
      const files = [...e.target.files];
      const data = new FormData();
      files.forEach((file: File) => {
        data.append('images', file);
      });
      const response = await feathersApp.service('uploads').create(data);
      onUpload(response);
    } catch (e) {
      onError(e.message);
    }
    setLoading(false);
  }, [onUpload, onError]);

  return (
    <div>
      {loading ? (
        <Spinner animation="border" /> 
        ) : (
        <Button as="label" htmlFor="file-upload-input">
          Upload
        </Button>
      )}
      <input onChange={uploadFiles} multiple={multiple} type="file" id="file-upload-input" accept="image/*" />
    </div>
  );
};

export default FileUploadInput;
