import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import Login from '../pages/Auth/Login';
import ForgotPassword from '../pages/ForgotPassword';
import Home from '../pages/Home';
import ResetPassword from '../pages/ResetPassword';

const AppRouter: React.FC = () => (
  <Router>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/reset" component={ResetPassword} />
      <Route path="/forgot" component={ForgotPassword} />
      <PrivateRoute path="/" component={Home} />
    </Switch>
  </Router>
);

export default AppRouter;