import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowsIcon } from './Small-arrows.svg';
import './styles.scss';

export interface BreadcrumbsItem {
  text: string;
  link?: string;
  active?: boolean;
}

interface BreadcrumbsPropsType {
  items: BreadcrumbsItem[];
}

const Breadcrumbs: React.FC<BreadcrumbsPropsType> = (props) => {
  const { items } = props;

  return (
    <div className="breadcrumbs">
      {items.map((item, index) => (
        <p key={item.text}>
          {!!index && <ArrowsIcon className="breadcrumbs_divider" />}
          {item.link
            ? (
              <Link
                className={`breadcrumbs_item ${item.active ? 'breadcrumbs_item--active': ''}`}
                to={item.link}
              >
                  {item.text}  
              </Link>)
            : (
              <span
                className={`breadcrumbs_item ${item.active ? 'breadcrumbs_item--active': ''}`}
              >
                {item.text}
              </span>
            )
          }
        </p>
        
      ))}
    </div>
  );
};

export default Breadcrumbs;
