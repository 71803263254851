import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useLoads } from 'react-loads';
import { RouteComponentProps } from 'react-router-dom';
import AddAssetModal from '../../components/AddAssetModal';
import AddVaribleModal from '../../components/AddVariableModal';
import AssetsList from '../../components/AssetsList';
import Breadcrumbs from '../../components/Breadcrumbs';
import ErrorModal from '../../components/ErrorModal';
import VariablesList from '../../components/VariablesList';
import { Asset, Language, Option, Page, Variable, Series } from '../../declarations';
import feathersApp from '../../utils/feathers';
import { socket } from '../../utils/socket';
import PageActions, { PAGE_STATUSES } from './PageActions';
import PagePreview from './PagePreview';
import PageProgress from './PageProgress';
import './styles.scss';

interface PageDetailsRouteParams {
  seriesId: string;
  issueId: string;
  pageId: string;
}

const PageDetails: React.FC<RouteComponentProps<PageDetailsRouteParams>> = props => {
  const { seriesId, issueId, pageId } = props.match.params;
  const [page, setPage] = useState<Partial<Page>>({});
  const [series, setSeries] = useState<Partial<Series>>({});
  const [variables, setVariables] = useState<Variable[]>([]);
  const [skippedVariablesId, setSkippedVariablesId] = useState<number[]>([]);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [variableModalVisible, setVariableModalVisible] = useState<boolean>(false);
  const [assetModalVisible, setAssetModalVisible] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<{ [key: number]: number }>([]);
  const [selectedLanguageId, setSelectedLanguageId] = useState<number | undefined>();
  const [selectedAssetsIds, setSelectedAssetsIds] = useState<number[]>([]);
  const [visibleAssetsIds, setVisibleAssetsIds] = useState<number[]>([]);
  const [error, setError] = useState('');
  const clearError = useCallback(() => setError(''), []);

  useEffect(() => {
    socket.on(`page/status_update/${pageId}`, (data: any) => {
      if (!data || !data.pageStatus) {
        return;
      }
      const { pageStatus, previewUrl } = data;
      setPage(p => ({
        ...p,
        pageStatus,
        previewUrl,
      }));
    });

    return () => {
      socket.off(`page/status_update/${pageId}`);
    };
  }, [pageId]);

  const showVariableModal = useCallback(() => setVariableModalVisible(true), []);
  const showAssetModal = useCallback(() => setAssetModalVisible(true), []);

  const saveVariable = useCallback(
    async (variable?: any) => {
      try {
        if (variable && !variable.id) {
          const data = {
            ...variable,
            pageId,
          };
          const createdVariable = await feathersApp
            .service('variables')
            .create(data, { query: { includeOptions: true } });
          setVariables([...variables, { ...createdVariable, level: 'page' }]);
        }
        if (variable && variable.id) {
          const updatedVar = await feathersApp.service('variables').patch(variable.id, variable);
          updatedVar.level = 'page';
          setVariables(variables.map(v => (v.id === updatedVar.id ? updatedVar : v)));
        }
        setVariableModalVisible(false);
      } catch (e) {
        setError(e.message);
      }
    },
    [pageId, variables],
  );

  const getPage = useCallback(async () => {
    try {
      const data = await feathersApp.service('pages').get(parseInt(pageId, 10));
      setPage(data);
    } catch (e) {
      setError(e.message);
    }
  }, [pageId]);

  const getVariables = useCallback(async () => {
    try {
      const res = await Promise.all([
        feathersApp.service('variables').find({ query: { seriesId, includeOptions: true } }),
        feathersApp.service('variables').find({ query: { issueId, includeOptions: true } }),
        feathersApp.service('variables').find({ query: { pageId, includeOptions: true } }),
      ]);
      res[0].data = res[0].data.map((variable: Variable) => ({
        ...variable,
        level: 'series',
      }));
      res[1].data = res[1].data.map((variable: Variable) => ({
        ...variable,
        level: 'issue',
      }));
      res[2].data = res[2].data.map((variable: Variable) => ({
        ...variable,
        level: 'page',
      }));
      const { data: skippedVars } = await feathersApp
        .service('skipped-variables')
        .find({ query: { pageId } });
      const vars = res.reduce((acc, val) => [...acc, ...val.data], []);
      setVariables(vars);
      setSkippedVariablesId(skippedVars.map((sv: any) => sv.variableId));
    } catch (e) {
      setError(e.message);
    }
  }, [seriesId, issueId, pageId]);

  const getAssets = useCallback(async () => {
    try {
      const { data } = await feathersApp.service('assets').find({
        query: {
          $sort: { number: 1 },
          includeOptions: true,
          pageId,
        },
      });
      setAssets(data);
    } catch (e) {
      setError(e.message);
    }
  }, [pageId]);

  const getLanguages = useCallback(async () => {
    try {
      const series = await feathersApp
        .service('series')
        .get(seriesId, { query: { includeLanguages: true } });
      setSeries(series);
      setLanguages(series.languages || []);
    } catch (e) {
      setError(e.message);
    }
  }, [seriesId]);

  const { isPending } = useLoads(getPage, {}, [pageId]);
  const { isPending: isPendingVariables } = useLoads(getVariables, {}, [seriesId, issueId, pageId]);
  const { isPending: isPendingAssets, load: reloadAssets } = useLoads(getAssets, {}, [pageId]);
  const { isPending: isPendingLanguages } = useLoads(getLanguages, {}, [seriesId]);

  const updateAssets = useCallback((assetId: number, data: any) => {
    setAssets(assets =>
      assets.map(asset => {
        if (asset.id !== assetId) return asset;
        return {
          ...asset,
          ...data,
        };
      }),
    );
  }, []);

  const saveAsset = useCallback(
    async asset => {
      const r = await feathersApp.service('assets').patch(asset.id, asset);

      updateAssets(asset.id, r);
    },
    [updateAssets],
  );

  const removeAsset = useCallback(
    async (id: number) => {
      try {
        await feathersApp.service('assets').remove(id);
        reloadAssets();
      } catch (e) {
        setError(e.message);
      }
    },
    [reloadAssets],
  );

  const saveAssets = useCallback(
    async (newAssets?: Asset[]) => {
      try {
        if (newAssets && newAssets.length) {
          const data = newAssets.map((asset, i) => ({
            ...asset,
            number: assets.length + 1 + i,
            pageId,
          }));
          await feathersApp.service('assets').create(data);
          reloadAssets();
        }
        setAssetModalVisible(false);
      } catch (e) {
        setError(e.message);
      }
    },
    [assets.length, pageId, reloadAssets],
  );

  const reorderAsset = useCallback(
    async (oldNumber: number, newNumber: number) => {
      if (oldNumber === newNumber) {
        return;
      }
      const asset = assets.find(a => oldNumber === a.number);
      if (!asset) {
        return;
      }
      try {
        await feathersApp
          .service('assets')
          .patch(asset.id, { number: newNumber, _previousNumber: oldNumber });
        reloadAssets();
      } catch (e) {
        setError(e.message);
      }
    },
    [assets, reloadAssets],
  );

  const linkAsset = useCallback(
    async (assetId: number, optionId: number) => {
      const findedAsset: Asset | undefined = assets.find(a => a.id === assetId);
      if (!findedAsset || !!findedAsset.options.find(o => o.id === optionId)) {
        return;
      }
      try {
        await feathersApp.service('assets').patch(assetId, {
          optionsIds: [...findedAsset.options.map(o => o.id), optionId],
        });
        const option = variables
          .reduce((acc, value) => [...acc, ...(value.options || [])], [] as Option[])
          .find(o => o.id === optionId);
        if (option) {
          setAssets(
            assets.map(asset =>
              asset.id === assetId ? { ...asset, options: [...asset.options, option] } : asset,
            ),
          );
        } else {
          reloadAssets();
        }
      } catch (e) {
        setError(e.message);
      }
    },
    [assets, reloadAssets, variables],
  );

  const linkAssetWithLanguage = useCallback(
    async (assetId: number, languageId: number) => {
      try {
        await feathersApp.service('assets').patch(assetId, { languageId });
        setAssets(assets.map(asset => (asset.id === assetId ? { ...asset, languageId } : asset)));
      } catch (e) {
        setError(e.message);
      }
    },
    [assets],
  );

  const unlinkAsset = useCallback(
    async (assetId: number) => {
      const body = {
        languageId: null,
        optionsIds: [],
      };
      try {
        await feathersApp.service('assets').patch(assetId, body);
        setAssets(
          assets.map(asset =>
            asset.id === assetId ? { ...asset, languageId: null, options: [] as Option[] } : asset,
          ),
        );
      } catch (e) {
        setError(e.message);
      }
    },
    [assets],
  );

  const toggleOptionSelected = useCallback((optionId: number, variableId: number) => {
    setSelectedOptions(previousOptions => {
      const newOptions = { ...previousOptions };
      if (newOptions[variableId] && newOptions[variableId] === optionId) {
        delete newOptions[variableId];
        return newOptions;
      } else {
        return {
          ...newOptions,
          [variableId]: optionId,
        };
      }
    });
  }, []);

  const toggleSelectedLanguage = useCallback(
    (languageId: number) => {
      if (languageId === selectedLanguageId) {
        setSelectedLanguageId(undefined);
      } else {
        setSelectedLanguageId(languageId);
      }
    },
    [selectedLanguageId],
  );

  const deleteVariable = useCallback(
    async (variableId: number) => {
      try {
        await feathersApp.service('variables').remove(variableId);
        if (!variables) {
          return;
        }
        setVariables(variables.filter(v => v.id !== variableId));
        reloadAssets();
      } catch (e) {
        setError(e.message);
      }
    },
    [variables, reloadAssets],
  );

  const skipVariable = useCallback(
    async (variableId: number) => {
      try {
        await feathersApp.service('skipped-variables').create({ variableId, pageId: page.id });
        setSkippedVariablesId([...skippedVariablesId, variableId]);
      } catch (e) {
        setError(e.message);
      }
    },
    [page.id, skippedVariablesId],
  );

  const includeVariable = useCallback(
    async (variableId: number) => {
      try {
        await feathersApp
          .service('skipped-variables')
          .remove(null, { variableId, pageId: page.id });
        setSkippedVariablesId(skippedVariablesId.filter(v => v !== variableId));
      } catch (e) {
        setError(e.message);
      }
    },
    [page.id, skippedVariablesId],
  );

  const toogleLanguageSkipped = useCallback(async () => {
    try {
      await feathersApp.service('pages').patch(page.id, { languageSkipped: !page.languageSkipped });
      setPage({ ...page, languageSkipped: !page.languageSkipped });
    } catch (e) {
      setError(e.message);
    }
  }, [page]);

  const isEditingDisabled = useMemo(() => {
    return (
      page.pageStatus === PAGE_STATUSES.submitted || page.pageStatus === PAGE_STATUSES.published
    );
  }, [page.pageStatus]);

  const setPageStatus = useCallback(
    (status: string) => {
      setPage({ ...page, pageStatus: status });
    },
    [page],
  );

  const toggleAssetSelected = useCallback(
    (assetId: number) => {
      if (selectedAssetsIds.includes(assetId)) {
        setSelectedAssetsIds(selectedAssetsIds.filter(id => assetId !== id));
      } else {
        setSelectedAssetsIds([...selectedAssetsIds, assetId]);
      }
    },
    [selectedAssetsIds],
  );

  const toggleAllAssetsSelected = useCallback(() => {
    if (selectedAssetsIds.length === assets.length) {
      setSelectedAssetsIds([]);
    } else {
      setSelectedAssetsIds(assets.map(a => a.id));
    }
  }, [selectedAssetsIds.length, assets]);

  const bulkAssetsUnlink = useCallback(async () => {
    const body = {
      languageId: null,
      optionsIds: [],
    };
    if (!selectedAssetsIds.length) {
      return;
    }
    try {
      await feathersApp
        .service('assets')
        .patch(null, body, { query: { id: { $in: selectedAssetsIds } } });
      setSelectedAssetsIds([]);
      reloadAssets();
    } catch (e) {
      setError(e.message);
    }
  }, [selectedAssetsIds, reloadAssets]);

  const bulkAssetsDelete = useCallback(async () => {
    if (!selectedAssetsIds.length) {
      return;
    }
    try {
      await feathersApp
        .service('assets')
        .remove(null, { query: { id: { $in: selectedAssetsIds } } });
      reloadAssets();
      setSelectedAssetsIds([]);
    } catch (e) {
      setError(e.message);
    }
  }, [selectedAssetsIds, reloadAssets]);

  const toggleAssetVisibility = useCallback(
    assetId => {
      if (visibleAssetsIds.includes(assetId)) {
        setVisibleAssetsIds(visibleAssetsIds.filter(id => assetId !== id));
      } else {
        setVisibleAssetsIds([...visibleAssetsIds, assetId]);
      }
    },
    [visibleAssetsIds],
  );

  const toggleAllAssetsVisible = useCallback(() => {
    if (visibleAssetsIds.length === assets.length) {
      setVisibleAssetsIds([]);
    } else {
      setVisibleAssetsIds(assets.map(a => a.id));
    }
  }, [visibleAssetsIds.length, assets]);

  const onDrop = useCallback(
    (result: DropResult) => {
      if (result.destination && result.destination.droppableId.startsWith('variable-option')) {
        const optionId = parseInt(result.destination.droppableId.split('-').pop() || '0');
        const assetId = parseInt(result.draggableId);
        if (!optionId || !assetId) {
          return;
        }
        linkAsset(assetId, optionId);
      } else if (result.destination && result.destination.droppableId === 'assets') {
        reorderAsset(result.source.index + 1, result.destination.index + 1);
      } else if (
        result.destination &&
        result.destination.droppableId.startsWith('language-option')
      ) {
        const languageId = parseInt(result.destination.droppableId.split('-').pop() || '0');
        const assetId = parseInt(result.draggableId);
        if (!languageId || !assetId) {
          return;
        }
        linkAssetWithLanguage(assetId, languageId);
      }
    },
    [linkAsset, linkAssetWithLanguage, reorderAsset],
  );

  const selectAssetOptions = useCallback(
    (assetId: number, visible?: boolean) => {
      const asset = assets.find(a => a.id === assetId);
      if (!asset) {
        return;
      }
      if (asset.languageId) {
        toggleSelectedLanguage(asset.languageId);
      }
      const selectedOptionsIds = Object.values(selectedOptions);
      asset.options.forEach(option => {
        if (visible !== selectedOptionsIds.includes(option.id)) {
          toggleOptionSelected(option.id, option.variableId);
        }
      });
    },
    [assets, selectedOptions, toggleSelectedLanguage, toggleOptionSelected],
  );

  if (isPending || isPendingVariables) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Breadcrumbs
        items={[
          {
            link: '/',
            text: 'Library',
          },
          {
            link: `/series/${seriesId}`,
            text: 'Series details',
          },
          {
            link: `/series/${seriesId}/issue/${issueId}`,
            text: 'Issue details',
          },
          {
            active: true,
            text: 'Page details',
          },
        ]}
      />
      <div className="page-details">
        <Row className="page-details_heading-wrapper">
          <h2>Page details</h2>
          <PageActions page={page} setStatus={setPageStatus} />
        </Row>
        <div>
          <PageProgress
            variables={variables}
            assets={assets}
            skippedVariablesIds={skippedVariablesId}
            languages={languages}
            isLanguagesSkipped={page.languageSkipped || false}
          />
          {page.description && (
            <div className="page-details_description-wrapper">
              <h4>Description</h4>
              <p>{page.description}</p>
            </div>
          )}
        </div>
        <div className="page-details_content-wrapper">
          <DragDropContext onDragEnd={onDrop}>
            <div className="page-details_variables-wrapper">
              <VariablesList
                isAvailableTextVariables={false}
                selectedOptionsIds={Object.values(selectedOptions)}
                toggleOptionSelected={toggleOptionSelected}
                variables={variables}
                deleteVariable={deleteVariable}
                updateVariable={saveVariable}
                skipVariable={skipVariable}
                includeVariable={includeVariable}
                skippedVariablesIds={skippedVariablesId}
                disabled={isEditingDisabled}
                languagesCardProps={
                  !isPendingLanguages
                    ? {
                        disabled: isEditingDisabled,
                        languages,
                        selectLanguage: toggleSelectedLanguage,
                        selectedLanguageId,
                        skipped: page.languageSkipped,
                        toggleLanguageSkipped: toogleLanguageSkipped,
                      }
                    : undefined
                }
              />
              {!isEditingDisabled && (
                <>
                  <Button onClick={showVariableModal}>Add Variable</Button>
                  <AddVaribleModal
                    visible={variableModalVisible}
                    onClose={saveVariable}
                    isAvailableTextVariables={false}
                  />
                </>
              )}
            </div>
            <div className="page-details_assets-wrapper">
              <AssetsList
                updateAssets={reloadAssets}
                unlinkAsset={unlinkAsset}
                removeAsset={removeAsset}
                assets={assets}
                disabled={isEditingDisabled}
                variablesLevels={variables.reduce(
                  (acc, value) => ({
                    ...acc,
                    [value.id]: value.level,
                  }),
                  {},
                )}
                selectedOptions={selectedOptions}
                selectedLanguageId={selectedLanguageId}
                selectedAssetsIds={selectedAssetsIds}
                toggleAssetSelected={toggleAssetSelected}
                toggleAllAssetsSelected={toggleAllAssetsSelected}
                bulkAssetsUnlink={bulkAssetsUnlink}
                bulkAssetsDelete={bulkAssetsDelete}
                visibleAssetsIds={visibleAssetsIds}
                toggleAssetVisible={toggleAssetVisibility}
                toggleAllAssetsVisible={toggleAllAssetsVisible}
                selectAssetOptions={selectAssetOptions}
              />
              {!isEditingDisabled && (
                <>
                  <Button onClick={showAssetModal}>Add Asset</Button>
                  <AddAssetModal visible={assetModalVisible} onClose={saveAssets} />
                </>
              )}
            </div>
          </DragDropContext>
          <div className="page-details_preview-wrapper">
            <PagePreview
              pageWidth={series.pageWidth}
              pageHeight={series.pageHeight}
              assets={assets}
              selectedOptionsIds={Object.values(selectedOptions)}
              selectedLanguageId={selectedLanguageId}
              visibleAssetsIds={visibleAssetsIds}
              saveAsset={saveAsset}
            />
          </div>
        </div>
      </div>
      <ErrorModal message={error} visible={!!error} onClose={clearError} />
    </>
  );
};

export default PageDetails;
