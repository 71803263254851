import React, { useEffect, useState, useRef, useContext, useMemo, useCallback } from 'react';
import { fabric } from 'fabric';
import useAsyncEffect from '@n1ru4l/use-async-effect';
import FontFaceObserver from 'fontfaceobserver';
import { Button } from 'react-bootstrap';
import feathersApp from '../../../utils/feathers';

interface ContextValue {
  canvas: fabric.Canvas | null;
  scale: number;
  width: number;
  height: number;
}

const CanvasContext = React.createContext<ContextValue>({
  canvas: null,
  scale: 1,
  width: 200,
  height: 300,
});

const mmToPx = 3.779527;

const useCanvasObject = <T extends fabric.Object>(
  objectGetter: (value: any, options?: any) => Promise<T> | T,
  value: any,
  options: any,
  order: number,
) => {
  const { canvas, scale, width } = useContext(CanvasContext);
  const selectedRef = useRef<boolean>(false);

  if (!canvas) throw new Error('canvas was not initialised');

  useAsyncEffect(
    function*() {
      const localObject = yield objectGetter(value, {
        ...options,
        zoomScale: scale,
      });
      canvas.add(localObject);
      localObject.moveTo(order);

      if (localObject.type === 'image') {
        const scaling =
          (canvas.getWidth() / localObject.width) * ((width * mmToPx) / canvas.getWidth());
        localObject.scale(scaling);
      }
      if (localObject.type === 'textbox') {
        localObject.scale(1);
      }

      if (selectedRef.current) {
        canvas.setActiveObject(localObject);
      }
      const onSelected = (opt: any) => {
        selectedRef.current = Boolean(
          options &&
            opt.selected &&
            opt.selected.find((selected: any) => selected.id === options.id),
        );
      };
      canvas.on('selection:created', onSelected);
      canvas.on('selection:updated', onSelected);
      canvas.on('selection:cleared', onSelected);

      return () => {
        canvas.off('selection:created', onSelected);
        canvas.off('selection:updated', onSelected);
        canvas.off('selection:cleared', onSelected);
        canvas.remove(localObject);
      };
    },
    [objectGetter, canvas, value, order, scale, options, width],
  );
};

interface CanvasProps {
  pageWidth: number;
  pageHeight: number;
}

export const Canvas: React.FC<CanvasProps> = React.memo(({ children, pageWidth, pageHeight }) => {
  const canvasRef = useRef<any>(null);
  const canvasWrapperRef = useRef<any>(null);
  const width = pageWidth;
  const height = pageHeight;

  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const canvasObjectRef = useRef<fabric.Canvas | null>(null);
  const [scale, setScale] = useState<number>(1);
  const scaleRef = useRef(1);

  const contextValue: ContextValue = useMemo(() => ({ canvas, scale, width, height }), [
    canvas,
    scale,
    width,
    height,
  ]);

  /*
  const [previewSize, setPreviewSize] = useState({ width: 400, height: 800 });

  useEffect(() => {
    // @ts-ignore
    const observer = new ResizeObserver(() => {
      console.log(canvasWrapperRef.current.offsetWidth, 'abc');
      setPreviewSize({
        width: canvasWrapperRef.current.offsetWidth,
        height: canvasWrapperRef.current.offsetHeight,
      });
    });
    observer.observe(canvasWrapperRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);
  */

  // create fabric canvas element
  useEffect(() => {
    canvasRef.current.width = canvasWrapperRef.current.offsetWidth;
    // canvasRef.current.height = previewSize.height;
    const c = new fabric.Canvas(canvasRef.current, { selection: false });
    // @ts-ignore
    canvasObjectRef.current = c;
    setCanvas(c);

    /*
    return () => {
      c.getElement().remove();
    };
    */
  }, []);

  // use mouse move
  // handle mouse movement to allow scrolling in the preview
  useEffect(() => {
    let isPanning = false;
    let lastMouseX = 0;
    let lastMouseY = 0;
    const c = canvasObjectRef.current;
    if (!c) return;

    const onMouseDown = (opt: fabric.IEvent): void => {
      isPanning = c.getActiveObject() == null;
      if (isPanning) {
        const e: any = opt.e;
        lastMouseX = e.clientX;
        lastMouseY = e.clientY;
      }
    };
    c.on('mouse:down', onMouseDown);

    const onMouseMove = (opt: fabric.IEvent): void => {
      if (isPanning) {
        const e: any = opt.e;
        const dx = e.clientX - lastMouseX;
        const dy = e.clientY - lastMouseY;
        c.viewportTransform = c.viewportTransform || [0, 0, 0, 0, 0, 0];
        const maxXOffset = c.getWidth() - width * mmToPx * scaleRef.current;
        c.viewportTransform[4] = Math.max(
          Math.min(c.viewportTransform[4] + dx, 0),
          maxXOffset > 0 ? maxXOffset / 2 : maxXOffset,
        );
        const maxYOffset = c.getHeight() - height * mmToPx * scaleRef.current;
        c.viewportTransform[5] = Math.max(
          Math.min(c.viewportTransform[5] + dy, 0),
          maxYOffset > 0 ? maxYOffset / 2 : maxYOffset,
        );
        c.requestRenderAll();
        c.forEachObject(obj => obj.setCoords());
        lastMouseX = e.clientX;
        lastMouseY = e.clientY;
      }
    };
    c.on('mouse:move', onMouseMove);

    const onMouseUp = (): void => {
      isPanning = false;
    };
    c.on('mouse:up', onMouseUp);

    return () => {
      c.off('mouse:down', onMouseDown);
      c.off('mouse:move', onMouseMove);
      c.off('mouse:up', onMouseUp);
    };
  }, [width, height]);

  // synchronise scale with the preview
  useEffect(() => {
    scaleRef.current = scale;
    const c = canvasObjectRef.current;
    if (!c || !c.viewportTransform) return;
    c.viewportTransform[0] = scale;
    c.viewportTransform[3] = scale;
    const maxXOffset = c.getWidth() - width * mmToPx * scale;
    c.viewportTransform[4] = Math.max(
      Math.min(c.viewportTransform[4], 0),
      maxXOffset > 0 ? maxXOffset / 2 : maxXOffset,
    );
    const maxYOffset = c.getHeight() - height * mmToPx * scale;
    c.viewportTransform[5] = Math.max(
      Math.min(c.viewportTransform[5], 0),
      maxYOffset > 0 ? maxYOffset / 2 : maxYOffset,
    );
  }, [scale, width, height]);

  const changeScale = useCallback(
    delta => {
      const c = canvasObjectRef.current;
      if (!c) return;
      setScale(scale =>
        Math.max(
          Math.min(c.getWidth() / (width * mmToPx), c.getHeight() / (height * mmToPx)),
          Math.round((scale + delta) * 10) / 10,
        ),
      );
    },
    [width, height],
  );

  // force scale to fit the page inside of the preview
  useEffect(() => {
    changeScale(0);
  }, [changeScale]);

  return (
    <CanvasContext.Provider value={contextValue}>
      <div style={{ width: '100%' }} ref={canvasWrapperRef}>
        <Button onClick={() => changeScale(-0.1)}>-</Button>
        <span>Scale: {scale.toFixed(2)}</span>
        <Button onClick={() => changeScale(0.1)}>+</Button>
        <canvas ref={canvasRef} width={500} height={750} />
        {Boolean(canvas) && children}
      </div>
    </CanvasContext.Provider>
  );
});

export const Image: React.FC<any> = React.memo(({ url, order }) => {
  const imageGetter = useCallback(async (value): Promise<fabric.Object> => {
    return new Promise(resolve => {
      fabric.Image.fromURL(
        value,
        img => {
          resolve(img);
        },
        { left: 0, top: 0, selectable: false, evented: false },
      );
    });
  }, []);

  useCanvasObject(imageGetter, url, null, order);

  return null;
});

export const Text: React.FC<any> = React.memo(({ text, order, saveAsset, item, ...options }) => {
  const { width, height } = useContext(CanvasContext);

  const textboxGetter = useCallback(
    async (value, options) => {
      const font = new FontFaceObserver('ltromatic');
      await font.load();
      const textbox = new fabric.Textbox(value, {
        ...options,
        left: (options && (options.offsetLeft / 100) * width * mmToPx) || 0,
        top: (options && (options.offsetTop / 100) * height * mmToPx) || 0,
        width: options.width ? (options.width / 100) * width * mmToPx : undefined,
        height: options.height ? (options.height / 100) * height * mmToPx : undefined,
        textAlign: 'center',
        fontFamily: 'ltromatic',
        fontSize: 10,
        lockRotation: true,
        hasRotatingPoint: false,
      });
      ['tl', 'tr', 'br', 'bl', 'mt', 'mb', 'mtr'].forEach(control =>
        textbox.setControlVisible(control, false),
      );
      textbox.on('modified', () => {
        saveAsset({
          id: item.id,
          width: ((textbox.width || 0) / (width * mmToPx)) * 100,
          height: ((textbox.height || 0) / (height * mmToPx)) * 100,
          offsetLeft: ((textbox.left || 0) / (width * mmToPx)) * 100,
          offsetTop: ((textbox.top || 0) / (height * mmToPx)) * 100,
        });
      });
      return textbox;
    },
    [width, height, item.id, saveAsset],
  );

  useCanvasObject(textboxGetter, text, options, order);

  return null;
});
