import React from 'react';
import './styles.scss';

const Footer: React.FC = () => {

  return (
    <div className="footer">ContentCo</div>
  );
};

export default Footer;
