import React, { useCallback, useMemo } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Asset, Language, Option, Variable } from '../../../declarations';
import './styles.scss';

export interface PageProgressPropsType {
  variables: Variable[];
  assets: Asset[];
  skippedVariablesIds: number[];
  languages: Language[];
  isLanguagesSkipped: boolean;
}

const PageProgress: React.FC<PageProgressPropsType> = (props) => {
  const { variables, assets, skippedVariablesIds, languages, isLanguagesSkipped } = props;
  const options = variables
    .filter(v => !skippedVariablesIds.includes(v.id))
    .reduce((acc, value) => [...acc, ...(value.options || [])], [] as Option[]);
  
  const linkedOptionsIds = useMemo(() => {
    return new Set(assets.reduce(
      (acc: number[], value: Asset) => [
        ...acc, 
        ...(value.options.map(o => o.id))], [] as number[]
      ).filter(o => !!o));
  }, [assets]);
  
  const linkedLanguagesIds = useMemo(() => {
    return isLanguagesSkipped ? new Set() : new Set(assets.map(a => a.languageId).filter(l => !!l));
  }, [isLanguagesSkipped, assets]);

  const totalLength = useMemo(
    () => (options.length + (isLanguagesSkipped ? 0 : languages.length)), 
    [options.length, isLanguagesSkipped, languages.length]
  );

  const linkedItemsLenght = useMemo(() => {
    return linkedOptionsIds.size + linkedLanguagesIds.size;
  }, [linkedOptionsIds.size, linkedLanguagesIds.size]);

  const getProgress = useCallback(() => {
    if(!totalLength) {
      return 100;
    }
    return Math.floor(linkedItemsLenght * 100 / totalLength)
  }, [linkedItemsLenght, totalLength]);

  return (
    <div className="page-progress">
      <h5>Progress</h5>
      <ProgressBar variant="success" now={getProgress()} label={`${linkedItemsLenght}/${totalLength}`} />
    </div>
  );
};

export default PageProgress;
